/* This example requires Tailwind CSS v2.0+ */
import { Transition } from '@headlessui/react';
import { ChevronUpIcon, ChevronDownIcon, PencilIcon, CheckIcon, XIcon } from '@heroicons/react/outline';
import { ReactNode, useEffect, useState } from 'react';
import { useSWRConfig } from 'swr';
import client from '../client';
import { formatDate } from '../utils';
import ConfirmationModal from './ConfirmationModal';
import Toggle from './Toggle';
import useForecasts from '../hooks/useForecasts';
import { useNavigate } from 'react-router-dom';

export default function ForecastDetails({ details, children }: { details: any; children: ReactNode }) {
    const { mutate } = useSWRConfig();
    const { forecasts } = useForecasts();
    const navigate = useNavigate();
    const [detailsShown, setDetailsShown] = useState(false);
    const [isEditingName, setIsEditingName] = useState(false);
    const [forecastName, setForecastName] = useState(details.name);
    const [budgetAlertShown, setBudgetAlertShown] = useState(false);

    useEffect(() => {
        setForecastName(details.name);
    }, [details]);

    const handleNameSave = async () => {
        const { data: forecast } = await client.patch(`/v1/forecast/${details.id}/`, {
            ...details,
            name: forecastName,
        });
        setIsEditingName(false);
        setForecastName(forecast.name);
        mutate(`/v1/forecast/${details.id}/`); //reset forecastdetails
    };

    const handleBudgetChange = async () => {
        await client.patch(`/v1/forecast/${details.id}/`, {
            ...details,
            is_budget: !details.is_budget,
        });
        setBudgetAlertShown(false);
        mutate(`/v1/forecast/${details.id}/`); //reset forecastdetails
    };

    return (
        <>
            <div className="flex items-center px-4 py-5 sm:px-6">
                {detailsShown ? (
                    <ChevronUpIcon
                        className="inline-flex text-gray-400 hover:text-gray-500 h-5 w-5 cursor-pointer"
                        onClick={() => setDetailsShown(false)}
                    />
                ) : (
                    <ChevronDownIcon
                        className="inline-flex text-gray-400 hover:text-gray-500 h-5 w-5 cursor-pointer"
                        onClick={() => setDetailsShown(true)}
                    />
                )}
                <h2
                    id="applicant-information-title"
                    className="ml-2 text-lg leading-6 font-medium text-gray-900 flex items-center"
                >
                    {isEditingName ? (
                        <div className="flex items-center">
                            <input
                                type="text"
                                name="name"
                                id="name"
                                value={forecastName}
                                onChange={(e) => {
                                    setForecastName(e.target.value);
                                }}
                                className="w-32 block shadow-sm focus:ring-ru-teal focus:border-ru-teal sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            />
                            <div className="ml-1">
                                <CheckIcon
                                    className="text-gray-400 hover:text-gray-500 h-4 w-4 cursor-pointer"
                                    onClick={() => handleNameSave()}
                                />
                                <XIcon
                                    className="text-gray-400 hover:text-gray-500 h-4 w-4 cursor-pointer"
                                    onClick={() => {
                                        setIsEditingName(false);
                                        setForecastName(details.name);
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            {forecastName}
                            <PencilIcon
                                className="ml-1 text-gray-400 hover:text-gray-500 h-4 w-4 cursor-pointer"
                                onClick={() => setIsEditingName(true)}
                            />
                        </>
                    )}
                </h2>
                <div className="mt-4 space-x-2 ml-auto sm:mt-0 flex items-center">
                    <div className="flex items-center mr-5">
                        <label htmlFor="strategy" className="block text-sm font-medium text-gray-700 mr-1">
                            Use as Budget
                        </label>
                        <div className="mt-1 ml-1">
                            <Toggle value={details.is_budget} onChange={() => setBudgetAlertShown(true)} />
                        </div>
                    </div>
                    {children}
                </div>
            </div>
            <Transition
                show={detailsShown}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-y-95"
                enterTo="transform opacity-100 scale-y-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-y-100"
                leaveTo="transform opacity-0 scale-y-95"
                className="origin-top"
            >
                <div className="origin-top relative border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                        {[
                            {
                                label: 'Start Date',
                                // value: toLocaleYearAndMonth(details.start_date),
                                value: formatDate(new Date(details.start_date), 'm-y'),
                            },
                            {
                                label: 'End Date',
                                // value: toLocaleYearAndMonth(details.end_date),
                                value: formatDate(new Date(details.end_date), 'm-y'),
                            },
                            {
                                label: 'Use Product Categories',
                                value: details?.use_product_categories ? 'Yes' : 'No',
                            },
                            {
                                label: 'Period Type',
                                value: details?.interval,
                            },
                            {
                                label: 'Number of Periods',
                                value: details?.number_of_intervals,
                            },
                            {
                                label: 'Target Data Copied From',
                                value: forecasts.find((f) => f.id == details?.copy_from_forecast)?.name || '-',
                                to: details?.copy_from_forecast
                                    ? `/forecasts/${details?.copy_from_forecast}/result`
                                    : null,
                            },
                            {
                                label: 'First Full Month of Actuals Data',
                                value: formatDate(new Date(details.raw_data_start_date), 'm-y'),
                            },
                            {
                                label: 'Last Full Month of Actuals Data',
                                value: formatDate(new Date(details.raw_data_end_date), 'm-y'),
                            },
                            {
                                label: 'Created By',
                                value: details?.full_name,
                            },
                        ].map(({ label, value, to }) => {
                            return (
                                <div
                                    key={label}
                                    className={'sm:col-span-1' + (to ? ' cursor-pointer' : '')}
                                    onClick={() => {
                                        if (to) navigate(to);
                                    }}
                                >
                                    <dt className="text-sm font-medium text-gray-500">{label}</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{value}</dd>
                                </div>
                            );
                        })}
                    </dl>
                </div>
            </Transition>
            <ConfirmationModal
                title="Are you sure you want to change your budget forecast?"
                isOpen={budgetAlertShown}
                closeModal={() => setBudgetAlertShown(false)}
                onConfirmation={handleBudgetChange}
            />
        </>
    );
}
