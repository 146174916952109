import React, { useEffect, useState, useMemo } from 'react';
import useSWR from 'swr';
import { Link, useNavigate, useParams } from 'react-router-dom';
import client from '../client';
import Loading from '../components/Loading';
import Toggle from '../components/Toggle';
import useAuthStore from '../stores/auth';
import useForecastStore from '../stores/forecasts';
import useLayoutStore from '../stores/layout';
import {
    dateWithFirstDayOfMonth,
    dateWithLastDayOfMonth,
    formatDate,
    getMonthOptions,
    getNewDateFromMonthOffset,
} from '../utils';

const forecastStartMonthOptions = getMonthOptions(3, 8);
const historicalFactsMonthOptions = getMonthOptions(0, 7);

export default function NewForecast() {
    const { forecastId } = useParams();
    const addForecastToPolling = useForecastStore((state) => state.addForecastToPolling);
    const setCustomBreadcrumbs = useLayoutStore((state) => state.setBreadcrumbOverride);
    const selectedAccount = useAuthStore((state) => state.selectedAccount);

    const navigate = useNavigate();
    const [forecastDetails, setForecastDetails] = useState({
        name: '',
        interval: 'month',
        numberOfIntervals: 12,
        startDate: forecastStartMonthOptions[3].value,
        endDate: 0,
        numberOfPreviousIntervals: 6,
        rawDataEndDate: historicalFactsMonthOptions[0].value,
        // strategy: 'strategy_1',
        use_product_categories: false,
        copyFromForecast: '',
        rawDataStartDate: new Date(),
    });
    const [submitPressed, setSubmitPressed] = useState(false);
    const [requestPending, setRequestPending] = useState(false);

    const { data: forecasts = [] } = useSWR(`/v1/forecast/`);
    let copyFromForecastOptions = useMemo(() => {
        return forecasts.filter(
            (fo: any) =>
                fo.use_product_categories === forecastDetails.use_product_categories,
        );
    }, [forecasts, forecastDetails.use_product_categories]);

    useEffect(() => {
        if (forecastDetails.startDate && !forecastId) {
            const endDate = getNewDateFromMonthOffset(
                new Date(+forecastDetails.startDate),
                forecastDetails.numberOfIntervals - 1,
            );
            setForecastDetails((state) => ({
                ...state,
                endDate: endDate.getTime(),
            }));
        }
    }, [forecastDetails.startDate, forecastId, forecastDetails.numberOfIntervals]);

    useEffect(() => {
        async function fetchForecast() {
            const { data } = await client.get(`/v1/forecast/${forecastId}/`);
            const {
                name,
                interval,
                number_of_intervals,
                raw_data_end_date,
                raw_data_start_date,
                start_date,
                end_date,
                // strategy,
                use_product_categories,
                copy_from_forecast,
            } = data;
            setForecastDetails((state) => ({
                ...state,
                name,
                interval,
                numberOfIntervals: number_of_intervals,
                startDate: start_date,
                endDate: end_date,
                rawDataEndDate: raw_data_end_date,
                rawDataStartDate: raw_data_start_date,
                // strategy,
                use_product_categories,
                copyFromForecast: copy_from_forecast,
            }));
        }
        if (forecastId) fetchForecast();
    }, [forecastId]);

    useEffect(() => {
        setCustomBreadcrumbs([
            { label: 'Forecasts', to: 'forecasts' },
            { label: 'New', to: '' },
        ]);
    }, [setCustomBreadcrumbs]);

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (forecastId) return;
        setForecastDetails((state) => ({
            ...state,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        if (!forecastId) {
            e.preventDefault();
            setSubmitPressed(true);
            setRequestPending(true);
            const {
                name,
                startDate,
                endDate,
                rawDataEndDate,
                numberOfPreviousIntervals,
                numberOfIntervals,
                // strategy,
                use_product_categories,
                copyFromForecast
            } = forecastDetails;
            const rawDataStartDate = getNewDateFromMonthOffset(
                new Date(+rawDataEndDate),
                -numberOfPreviousIntervals + 1, // +1 because the first month start with 1st, and the last month ends with 30/31
            );
            try {
                const { data: forecast } = await client.post('/v1/forecast/', {
                    account: selectedAccount,
                    // strategy,
                    name,
                    start_date: formatDate(dateWithFirstDayOfMonth(new Date(+startDate))),
                    end_date: formatDate(dateWithLastDayOfMonth(new Date(+endDate))),
                    raw_data_end_date: formatDate(dateWithLastDayOfMonth(new Date(+rawDataEndDate))),
                    raw_data_start_date: formatDate(rawDataStartDate, 'y-m-d', false),
                    use_product_categories,
                    copy_from_forecast: copyFromForecast,
                    number_of_intervals: numberOfIntervals,
                });
                addForecastToPolling(forecast.id);
                navigate('/forecasts');
            } catch (error) {
                setRequestPending(false);
            }
        } else {
            navigate(
                forecastDetails.use_product_categories
                    ? `/forecasts/${forecastId}/product-categories`
                    : `/forecasts/${forecastId}/products`,
            );
        }
    };
    return (
        <div className="py-2">
            <div className="px-4 sm:px-6 lg:px-8">
                <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Main Details</h3>
                                <p className="pt-5 text-gray-500">
                                    This is the start of your forecast journey. Here you can create a new forecast by
                                    filling out the form and click 'Create forecast'. Some field are required, such as
                                    'Name' and 'Start period'. Other fields are optional, such as 'Number of periods'.
                                    If you don't fill out this field, we will take the default value, which in this case
                                    12.
                                </p>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Name
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            disabled={!!forecastId}
                                            value={forecastDetails.name}
                                            onChange={changeHandler}
                                            className="max-w-lg block w-full shadow-sm focus:ring-ru-teal focus:border-ru-teal sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {!forecastDetails.name && submitPressed && (
                                            <p className="mt-2 text-sm text-red-600" id="name-error">
                                                Required field
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="interval"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Period Type
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <input
                                            value={forecastDetails.interval}
                                            disabled={true}
                                            type="text"
                                            name="interval"
                                            id="interval"
                                            className="max-w-lg block w-full shadow-sm focus:ring-ru-teal focus:border-ru-teal sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="numberOfIntervals"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Number of Periods (1 to 24)
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <input
                                            value={forecastDetails.numberOfIntervals}
                                            onChange={changeHandler}
                                            id="numberOfIntervals"
                                            name="numberOfIntervals"
                                            type="number"
                                            min={1}
                                            max={24}
                                            className="block max-w-lg w-full shadow-sm focus:ring-ru-teal focus:border-ru-teal sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="startDate"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Start Period
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        {forecastId ? (
                                            <input
                                                value={new Date(forecastDetails.startDate).toLocaleString('default', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                })}
                                                disabled={true}
                                                type="text"
                                                id="startDate"
                                                name="startDate"
                                                className="block max-w-lg w-full shadow-sm focus:ring-ru-teal focus:border-ru-teal sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                            />
                                        ) : (
                                            <select
                                                id="startDate"
                                                name="startDate"
                                                value={forecastDetails.startDate}
                                                onChange={changeHandler}
                                                className="max-w-lg block w-full shadow-sm focus:ring-ru-teal focus:border-ru-teal sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                            >
                                                {forecastStartMonthOptions.map((d: any) => {
                                                    return (
                                                        <option value={d.value} key={d.value}>
                                                            {new Date(d.value).toLocaleString('default', {
                                                                year: 'numeric',
                                                                month: 'long',
                                                            })}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        )}
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="endDate"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        End Period
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <input
                                            value={new Date(forecastDetails.endDate).toLocaleString('default', {
                                                year: 'numeric',
                                                month: 'long',
                                            })}
                                            disabled={true}
                                            type="text"
                                            name="endDate"
                                            id="endDate"
                                            className="max-w-lg block w-full shadow-sm focus:ring-ru-teal focus:border-ru-teal sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 items-center sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="strategy"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Use Product Categories
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <Toggle
                                            value={forecastDetails.use_product_categories}
                                            onChange={() =>
                                                setForecastDetails((state) => ({
                                                    ...state,
                                                    use_product_categories: !state.use_product_categories,
                                                }))
                                            }
                                            disabled={!!forecastId}
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="copyFromForecast"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Copy Target Data From {forecastDetails.use_product_categories ? 'Category' : 'Product'} Forecast
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        {forecastId ? (
                                            <input
                                                value={forecastDetails.copyFromForecast}
                                                disabled={true}
                                                type="text"
                                                id="copyFromForecast"
                                                name="copyFromForecast"
                                                className="block max-w-lg w-full shadow-sm focus:ring-ru-teal focus:border-ru-teal sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                            />
                                        ) : (
                                            <select
                                                id="copyFromForecast"
                                                name="copyFromForecast"
                                                value={forecastDetails.copyFromForecast}
                                                onChange={changeHandler}
                                                className="max-w-lg block w-full shadow-sm focus:ring-ru-teal focus:border-ru-teal sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                            >
                                                <option value={undefined}>None</option>
                                                {copyFromForecastOptions.map((d: any) => {
                                                    return (
                                                        <option value={d.id} key={d.id}>
                                                            {d.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Actuals Data</h3>
                            </div>
                            <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="rawDataEndDate"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Last Full Month of Actuals Data
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        {forecastId ? (
                                            <input
                                                value={new Date(forecastDetails.rawDataEndDate).toLocaleString(
                                                    'default',
                                                    {
                                                        year: 'numeric',
                                                        month: 'long',
                                                    },
                                                )}
                                                disabled={true}
                                                type="text"
                                                id="rawDataEndDate"
                                                name="rawDataEndDate"
                                                className="block max-w-lg w-full shadow-sm focus:ring-ru-teal focus:border-ru-teal sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                            />
                                        ) : (
                                            <select
                                                id="rawDataEndDate"
                                                name="rawDataEndDate"
                                                value={forecastDetails.rawDataEndDate}
                                                onChange={changeHandler}
                                                className="max-w-lg block w-full shadow-sm focus:ring-ru-teal focus:border-ru-teal sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                            >
                                                {historicalFactsMonthOptions.map((d: any) => {
                                                    return (
                                                        <option value={d.value} key={d.value}>
                                                            {new Date(d.value).toLocaleString('default', {
                                                                year: 'numeric',
                                                                month: 'long',
                                                            })}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        )}
                                    </div>
                                </div>
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="numberOfPreviousIntervals"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Number of Months
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <select
                                            id="numberOfPreviousIntervals"
                                            name="numberOfPreviousIntervals"
                                            value={
                                                forecastId
                                                    ? Math.abs(
                                                          // get the diff between the months
                                                          new Date(forecastDetails.rawDataEndDate).getMonth() +
                                                              1 -
                                                              new Date(forecastDetails.rawDataStartDate).getMonth(),
                                                      )
                                                    : forecastDetails.numberOfPreviousIntervals
                                            }
                                            onChange={changeHandler}
                                            disabled={!!forecastId}
                                            className="max-w-lg block w-full shadow-sm focus:ring-ru-teal focus:border-ru-teal sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        >
                                            <option>6</option>
                                            <option>3</option>
                                            <option>1</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className="flex justify-end">
                            <Link
                                to="/forecasts"
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ru-teal"
                            >
                                Cancel
                            </Link>
                            <button
                                disabled={requestPending}
                                type="submit"
                                className="ml-3 inline-flex items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ru-teal hover:bg-ru-teal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ru-teal disabled:bg-gray-400"
                            >
                                {requestPending ? <Loading size="small" /> : 'Create Forecast'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
