import { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import Loading from '../components/Loading';
import { classNames, formatDate, formatToEuros, utcDateFromString } from '../utils';
import useLayoutStore from '../stores/layout';
import client from '../client';
import Slideover from '../components/Slideover';
import ForecastProductTable from '../components/ForecastProductTable';
import ForecastChart from '../components/ForecastChart';
import ForecastEditorInputs from '../components/ForecastEditorInputs';
import useForecastStore from '../stores/forecasts';
import ConfirmationModal from '../components/ConfirmationModal';
import ForecastDetails from '../components/ForecastDetails';

export default function ForecastEdit() {
    const { forecastId } = useParams();
    const navigate = useNavigate();
    const setCustomBreadcrumbs = useLayoutStore((state) => state.setBreadcrumbOverride);
    const addForecastToPolling = useForecastStore((state) => state.addForecastToPolling);
    const [productListShown, setProductlistShown] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<null | number>(null);
    const [finishAlertShown, setFinishAlertShown] = useState(false);

    const { data: chartData, mutate: mutateChart } = useSWR(`/v1/forecast/${forecastId}/charts/?chart_id=1`);
    const { data: forecastDetails = {} } = useSWR(`/v1/forecast/${forecastId}/`);
    const { data: products = [] } = useSWR(() =>
        forecastDetails.use_product_categories ? null : `/v1/forecast/${forecastId}/products/`,
    );
    const { data: productCategories = [] } = useSWR(() =>
        forecastDetails.use_product_categories ? `/v1/forecast/${forecastId}/product-categories/` : null,
    );

    const itemList = useMemo(() => {
        return forecastDetails && forecastDetails.use_product_categories ? productCategories : products;
    }, [forecastDetails, productCategories, products]);

    useEffect(() => {
        setCustomBreadcrumbs([
            { label: 'Forecasts', to: 'forecasts' },
            { label: forecastDetails?.name || '', to: '' },

            {
                label: itemList.find((p: any) => p.id === selectedProduct)
                    ? itemList.find((p: any) => p.id === selectedProduct)?.name
                    : `All ${forecastDetails.use_product_categories ? 'Categories' : 'Products'}`,
                to: '',
            },
        ]);
    }, [forecastDetails, setCustomBreadcrumbs, itemList, selectedProduct]);

    const [invoiceAmount, orderAmount, opportunityAmount, unknownAmount, totalAmount, targetAmount] = useMemo(() => {
        return [
            'invoice_amount',
            'order_amount',
            'opportunity_amount',
            'unknown_amount',
            'total_amount',
            'target_amount',
        ].map((key) => {
            return createBarData(
                chartData?.bar_data,
                key,
                true,
                itemList.find((p: any) => p.id === selectedProduct)?.name, //change this to id
                forecastDetails.use_product_categories || false,
            );
        });
    }, [chartData?.bar_data, selectedProduct, itemList, forecastDetails.use_product_categories]);

    const handleDownload = async () => {
        //hacky axios file download
        const res = await client({
            url: `/v1/forecast/${forecastId}/download/`,
            method: 'GET',
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${forecastDetails.name}-${formatDate(new Date(), 'y-m-d-hms', false)}.csv`);
        document.body.appendChild(link);
        link.click();
    };

    const handleFinish = async () => {
        await client.patch(`/v1/forecast/${forecastId}/finish/`, {});
        if (forecastId) addForecastToPolling(+forecastId);
        navigate('/');
    };

    if (!forecastDetails) return null;
    return (
        <>
            <div className="py-2">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="bg-white shadow sm:rounded-lg">
                        <ForecastDetails details={forecastDetails}>
                            <Link
                                to={`/forecasts/${forecastId}/result/`}
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-400 px-4 py-1 text-sm font-medium text-white shadow-sm hover:bg-ru-blue focus:outline-none focus:ring-2 focus:ring-ru-teal focus:ring-offset-2 sm:w-auto"
                            >
                                View
                            </Link>
                            <button
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-400 px-4 py-1 text-sm font-medium text-white shadow-sm hover:bg-ru-blue focus:outline-none focus:ring-2 focus:ring-ru-teal focus:ring-offset-2 sm:w-auto"
                                onClick={handleDownload}
                            >
                                Download CSV
                            </button>
                            {forecastDetails?.status !== 'completed' && forecastDetails?.status !== 'finishing' && (
                                <button
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-400 px-4 py-1 text-sm font-medium text-white shadow-sm hover:bg-ru-blue focus:outline-none focus:ring-2 focus:ring-ru-teal focus:ring-offset-2 sm:w-auto"
                                    onClick={() => {
                                        setFinishAlertShown(true);
                                    }}
                                >
                                    Finish
                                </button>
                            )}
                        </ForecastDetails>
                    </div>
                    <div className="mt-6">
                        <div className="px-2">
                            {chartData ? (
                                <ForecastChart
                                    amountsList={[
                                        invoiceAmount,
                                        orderAmount,
                                        opportunityAmount,
                                        unknownAmount,
                                        targetAmount,
                                    ]}
                                    startDate={forecastDetails?.start_date}
                                />
                            ) : (
                                <div className="flex flex-row items-center justify-center mt-12">
                                    <Loading size="large" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mt-6">
                        <div className="p-4 bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="p-3 rounded-md flex items-center justify-between flex-wrap sm:flex-nowrap bg-ru-blue">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-white">
                                        {itemList.find((p: any) => p.id === selectedProduct)?.name ||
                                            `All ${forecastDetails.use_product_categories ? 'Categories' : 'Products'}`}
                                    </h3>
                                </div>
                                <div className="flex-shrink-0 space-x-1">
                                    <button
                                        onClick={() => {
                                            if (!selectedProduct) {
                                                setSelectedProduct(itemList[itemList.length - 1].id);
                                            } else {
                                                const prevIndex =
                                                    itemList.findIndex((p: any) => p.id === selectedProduct) - 1;
                                                setSelectedProduct(itemList[prevIndex] ? itemList[prevIndex].id : null);
                                            }
                                        }}
                                        type="button"
                                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-1 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-400 hover:text-white sm:w-auto"
                                    >
                                        Previous
                                    </button>
                                    <button
                                        onClick={() => setProductlistShown(true)}
                                        type="button"
                                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-1 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-400 hover:text-white sm:w-auto"
                                    >
                                        Select {forecastDetails.use_product_categories ? 'Category' : 'Product'}
                                    </button>
                                    <button
                                        onClick={() => {
                                            if (!selectedProduct) {
                                                setSelectedProduct(itemList[0].id);
                                            } else {
                                                const nextIndex =
                                                    itemList.findIndex((p: any) => p.id === selectedProduct) + 1;
                                                setSelectedProduct(itemList[nextIndex] ? itemList[nextIndex].id : null);
                                            }
                                        }}
                                        type="button"
                                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-1 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-400 hover:text-white sm:w-auto"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                            {selectedProduct && (
                                <div className="mt-6">
                                    <ForecastEditorInputs
                                        isCategory={forecastDetails.use_product_categories}
                                        selectedProduct={selectedProduct}
                                        mutateChart={mutateChart}
                                    />
                                </div>
                            )}
                            <div className="mt-8 flex flex-col">
                                <ForecastProductTable
                                    chartData={chartData}
                                    amountsList={[
                                        invoiceAmount,
                                        orderAmount,
                                        opportunityAmount,
                                        unknownAmount,
                                        totalAmount,
                                        targetAmount,
                                    ]}
                                    startDate={forecastDetails?.start_date}
                                    invoiceDataEndDate={forecastDetails.raw_data_end_date}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Slideover
                open={productListShown}
                setOpen={setProductlistShown}
                title={forecastDetails.use_product_categories ? 'Categories' : 'Products'}
            >
                <div>
                    <div
                        className={classNames(
                            selectedProduct ? '' : 'font-semibold',
                            'p-2 text-gray-900 cursor-pointer hover:bg-gray-200',
                        )}
                        onClick={() => {
                            setSelectedProduct(null);
                            setProductlistShown(false);
                        }}
                    >
                        All {forecastDetails.use_product_categories ? 'Categories' : 'Products'}
                    </div>
                    {itemList.map((p: any) => (
                        <div
                            key={p.id}
                            className={classNames(
                                p.id === selectedProduct ? 'font-semibold' : '',
                                'p-2 text-gray-900 cursor-pointer hover:bg-gray-200',
                            )}
                            onClick={() => {
                                setSelectedProduct(p.id);
                                setProductlistShown(false);
                            }}
                        >
                            {p.name}
                        </div>
                    ))}
                </div>
            </Slideover>
            <ConfirmationModal
                title="Are you sure you want to finish this forecast?"
                isOpen={finishAlertShown}
                closeModal={() => setFinishAlertShown(false)}
                onConfirmation={handleFinish}
            />
        </>
    );
}

export const createBarData = (
    array: any[] = [],
    key: string,
    labels: boolean = false,
    selectedItem: number | null = null,
    isCategory: boolean = false,
) => {
    return array.map((d: any) => {
        const amount = d.amounts.reduce((sum: any, current: any) => {
            if (
                !selectedItem ||
                (selectedItem &&
                    (isCategory ? selectedItem === current.category : selectedItem === current.product_name))
            ) {
                return sum + (+current[key] || 0);
            } else {
                return sum;
            }
        }, 0);
        return {
            date: utcDateFromString(d.interval_date),
            amount,
            ...(labels
                ? {
                      label: `${DICTIONARY[key]}: ${formatToEuros(amount, {
                          minimumFractionDigits: 2,
                      })}`,
                  }
                : {}),
        };
    });
};

const DICTIONARY: Record<string, string> = {
    invoice_amount: 'Realized revenue',
    order_amount: 'Contracted revenue',
    opportunity_amount: 'Opportunity revenue',
    unknown_amount: 'Unidentified revenue',
    total_amount: 'Total revenue',
    target_amount: 'Target revenue',
};
