import { isSameMonth } from 'date-fns';

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export function utcDateFromString(dateString: string) {
    const [year, month, day] = dateString.split('-');
    return new Date(Date.UTC(+year, +month - 1, +day));
}

export function getMonthOptions(from = 0, monthCount = 50, currentDate = new Date()) {
    const dateOptions = [];
    const current = dateWithLastDayOfMonth(currentDate);
    current.setDate(1);
    current.setMonth(current.getMonth() + from);
    for (let index = 0; index < monthCount; index++) {
        dateOptions.push({
            value: current.getTime(),
            label: toLocaleYearAndMonth(current),
        });
        current.setMonth(current.getMonth() - 1);
    }
    return dateOptions;
}

export function formatDate(d: Date, template = 'y-m-d', useUTC = true) {
    const year = d[`get${useUTC ? 'UTC' : ''}FullYear`]().toString();
    const month = ('0' + (d[`get${useUTC ? 'UTC' : ''}Month`]() + 1)).slice(-2);
    const day = ('0' + d[`get${useUTC ? 'UTC' : ''}Date`]()).slice(-2);
    const hours = ('0' + d[`get${useUTC ? 'UTC' : ''}Hours`]()).slice(-2);
    const minutes = ('0' + d[`get${useUTC ? 'UTC' : ''}Minutes`]()).slice(-2);
    const seconds = ('0' + d[`get${useUTC ? 'UTC' : ''}Seconds`]()).slice(-2);

    return template
        .replace('y', year)
        .replace('m', month)
        .replace('d', day)
        .replace('h', hours)
        .replace('m', minutes)
        .replace('s', seconds);
}

export function getNewDateFromMonthOffset(prevDate: Date, monthOffset: number) {
    const endDate = new Date(prevDate.getTime());
    endDate.setMonth(prevDate.getMonth() + monthOffset);
    return endDate;
}

export function dateWithLastDayOfMonth(date: Date) {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)); //get last day of month by setting it to the 0. day of the next month
}

export function dateWithFirstDayOfMonth(date: Date) {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1)); //get last day of month by setting it to the 0. day of the next month
}

export function toLocaleYearAndMonth(date: Date) {
    if (!date) return null;
    return date.toLocaleString('default', {
        year: 'numeric',
        month: 'numeric',
    });
}

export function getStrategyTitle(strategy: string) {
    switch (strategy) {
        case 'strategy_1':
            return 'Set Targets by Product (Absolute Amount)';
        case 'strategy_2':
            return 'Set Targets by Product (Growth Percentage)';
        default:
    }
}

export function formatToEuros(value: number, options = {}) {
    const defaultOptions = {
        style: 'decimal',
        currency: 'EUR',
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    };
    return Intl.NumberFormat('nl', { ...defaultOptions, ...options }).format(value);
}

export function removeNonNumeric(num: string) {
    return num.toString().replace(/[^0-9]/g, '');
}

export const dateHasInvoiceData = (invoiceData: any, date: any) => {
    return invoiceData.find((d: any) => {
        return d.amount > 0 && isSameMonth(d.date, date.date);
    });
};

export const isDateInForecast = (forecastData: any, date: any) => {
    const [startYear, startMonth] = forecastData.start_date.split('-');
    const startDateInMonths = startYear * 12 + +startMonth;

    const [endYear, endMonth] = forecastData.end_date.split('-');
    const endDateInMonths = endYear * 12 + +endMonth;

    return date?.value >= startDateInMonths && date?.value <= endDateInMonths;
};
