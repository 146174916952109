import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { MenuAlt2Icon, UserCircleIcon } from '@heroicons/react/outline';
import useAuthStore from '../stores/auth';
import useLayoutStore from '../stores/layout';
import { classNames } from '../utils';
import shallow from 'zustand/shallow';
import { useNavigate } from 'react-router-dom';

export default function Header() {
    const navigate = useNavigate();
    const [logout, accounts, selectedAccount, setSelectedAccount] = useAuthStore(
        (state) => [state.logout, state.accounts, state.selectedAccount, state.setSelectedAccount],
        shallow,
    );
    const setSidebarOpen = useLayoutStore((state) => state.setSidebarOpen);

    return (
        <div className="sticky top-0 z-20 flex-shrink-0 flex h-16 bg-white shadow">
            <button
                type="button"
                className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ru-blue md:hidden"
                onClick={() => setSidebarOpen(true)}
            >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
                <div className="flex-1 flex items-center">
                    <h1 className="ml-1 text-lg font-semibold text-gray-900">{accounts[selectedAccount] || ''}</h1>
                </div>
                <div className="ml-4 flex items-center md:ml-6">
                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                        <div>
                            <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ru-blue">
                                <span className="sr-only">Open user menu</span>
                                <UserCircleIcon className="h-8 w-8 rounded-full" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="px-4 py-3 border-b-2 border-gray-100">
                                    <p className="text-sm text-gray-700">Accounts</p>
                                </div>
                                {Object.entries(accounts).map(([key, value], i) => {
                                    return (
                                        <Menu.Item key={i}>
                                            {({ active }) => (
                                                <div
                                                    className={classNames(
                                                        active ? 'bg-gray-100' : '',
                                                        selectedAccount === key ? 'font-semibold' : '',
                                                        'block px-4 pl-6 py-2 text-sm text-gray-700',
                                                    )}
                                                    onClick={() => {
                                                        setSelectedAccount(key);
                                                        navigate('/');
                                                    }}
                                                >
                                                    {value}
                                                </div>
                                            )}
                                        </Menu.Item>
                                    );
                                })}
                                <Menu.Item>
                                    {({ active }) => (
                                        <div
                                            className={classNames(
                                                active ? 'bg-gray-100' : '',
                                                'block px-4 py-2 text-sm text-gray-700',
                                            )}
                                            onClick={logout}
                                        >
                                            Log out
                                        </div>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </div>
    );
}
