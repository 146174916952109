import { classNames } from '../utils';

interface LoadingProps {
    size?: 'small' | 'medium' | 'large';
}

const sizeClasses = {
    small: 'w-4 h-4 border-2',
    medium: 'w-8 h-8 border-4',
    large: 'w-16 h-16 border-8',
};
export default function Loading({ size = 'medium' }: LoadingProps) {
    return (
        <div className="flex flex-row items-center justify-center">
            <div
                style={{ borderTopColor: 'transparent' }}
                className={classNames(sizeClasses[size], 'border-ru-blue border-solid rounded-full animate-spin')}
            ></div>
        </div>
    );
}
