import { VictoryChart, VictoryTheme, VictoryAxis, VictoryBar, VictoryLabel, VictoryLabelProps } from 'victory';
import { formatToEuros } from '../utils';

interface GapAnalysisChartProps {
    forecastData: any;
    budgetData: any;
    selectedForecastName: string;
    budgetForecastName: string;
    isCategory?: boolean;
    useActuals?: boolean;
}

export default function GapAnalysisChart({
    forecastData,
    budgetData,
    selectedForecastName,
    budgetForecastName,
    isCategory = true,
    useActuals = false,
}: GapAnalysisChartProps) {
    const products: Record<string, any> = {};

    forecastData.forEach((interval: any, i: number) => {
        interval?.amounts?.forEach((product: any, j: number) => {
            const { product_code, product_name, product_short_name, total_amount, invoice_amount, category } = product;
            const key = isCategory ? category : product_code;
            if (!products[key]) {
                products[key] = {
                    name: isCategory ? category : product_short_name ? product_short_name : product_name,
                    total: total_amount + (useActuals ? invoice_amount : 0),
                    budget:
                        budgetData?.[i]?.amounts[j]?.total_amount +
                        (useActuals ? budgetData?.[i]?.amounts[j]?.invoice_amount : 0),
                };
            } else {
                products[key].total += total_amount + (useActuals ? invoice_amount : 0);
                products[key].budget +=
                    budgetData?.[i]?.amounts[j]?.total_amount +
                    (useActuals ? budgetData?.[i]?.amounts[j]?.invoice_amount : 0);
            }
        });
    });

    let chartData = Object.entries(products)
        .map(([key, product]) => ({ key, ...product }))
        .sort((a, b) => a.total - b.total);

    return (
        <VictoryChart
            domainPadding={20}
            theme={VictoryTheme.material}
            width={1000}
            height={chartData.length * 40 + 20}
            padding={{ left: 240, right: 170, top: 20, bottom: 5 }}
            style={{
                parent: {
                    padding: '15px',
                },
            }}
        >
            <VictoryAxis style={{ tickLabels: { display: 'none' } }} />
            <VictoryAxis dependentAxis domain={{ x: [0, 50000000] }} />
            <VictoryBar
                horizontal
                style={{
                    data: {
                        fill: '#7A7A7A',
                    },
                }}
                cornerRadius={{ top: 5 }}
                alignment="middle"
                barWidth={25}
                data={chartData}
                y={(d) => d.total}
                x={(d) => d.name}
                labels={({ datum }) => {
                    return datum.total >= datum.budget
                        ? null
                        : formatToEuros((datum.total - datum.budget) / 1000, {
                              maximumFractionDigits: 0,
                          }) + 'k';
                }}
                labelComponent={<CustomLabelComponent />}
            />
            <VictoryBar
                horizontal
                style={{
                    data: {
                        fill: ({ datum }) => (datum.total > datum.budget ? '#CDF46E' : '#81CBDD'),
                    },
                }}
                cornerRadius={{ top: 10 }}
                alignment="middle"
                barWidth={20}
                data={chartData}
                y={(d) => d.total + (d.total - d.budget)}
                y0={(d) => d.total}
                x={(d) => d.name}
                labels={({ datum }) => {
                    return datum.total > datum.budget
                        ? formatToEuros((datum.total - datum.budget) / 1000, {
                              maximumFractionDigits: 0,
                          }) + 'k'
                        : null;
                }}
                labelComponent={<CustomLabelHeaderComponent names={[selectedForecastName, budgetForecastName]} />}
            />
        </VictoryChart>
    );
}

function CustomLabelComponent(props: VictoryLabelProps) {
    return (
        <g>
            <VictoryLabel
                {...props}
                textAnchor="start"
                verticalAnchor="middle"
                text={({ datum }) => (datum.xName.length > 30 ? datum.xName.slice(0, 29) + '...' : datum.xName)}
                x={20}
                style={{
                    fontSize: 10,
                    width: 60,
                    fill: '#455a64',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                }}
            />
            <VictoryLabel
                {...props}
                dx={10}
                style={{
                    fontSize: 10,
                }}
            />
            <VictoryLabel
                {...props}
                textAnchor="end"
                verticalAnchor="middle"
                text={({ datum }) => {
                    return (
                        formatToEuros(datum.total / 1000, {
                            maximumFractionDigits: 0,
                        }) + 'k'
                    );
                }}
                dx={-10}
                x={240}
                style={{
                    fontSize: 10,
                }}
            />
            <VictoryLabel
                {...props}
                textAnchor="end"
                verticalAnchor="middle"
                text={({ datum }) =>
                    formatToEuros(datum.budget / 1000, {
                        maximumFractionDigits: 0,
                    }) + 'k'
                }
                dy={0}
                dx={-10}
                x={910}
                style={{
                    fontSize: 10,
                }}
            />
        </g>
    );
}

function CustomLabelHeaderComponent(props: VictoryLabelProps & { index?: number; names: string[] }) {
    if (props?.index !== 0) return null; // a little cheating to avoid duplicated rendering, we only need the header once
    return (
        <g>
            <VictoryLabel
                {...props}
                textAnchor="start"
                verticalAnchor="start"
                text={'Product Name'}
                dx={0}
                y={0}
                x={20}
                style={{
                    fontSize: 10,
                    width: 60,
                    fill: '#455a64',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                }}
            />
            <VictoryLabel
                {...props}
                textAnchor="end"
                verticalAnchor="start"
                text={props.names[0]}
                dx={0}
                y={0}
                x={240}
                style={{
                    fontSize: 10,
                    width: 60,
                    fill: '#455a64',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                }}
            />
            <VictoryLabel
                {...props}
                textAnchor="middle"
                verticalAnchor="start"
                text={'Difference'}
                dx={0}
                y={0}
                x={550}
                style={{
                    fontSize: 10,
                    width: 60,
                    fill: '#455a64',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                }}
            />
            <VictoryLabel
                {...props}
                textAnchor="start"
                verticalAnchor="start"
                text={props.names[1]}
                dx={0}
                y={0}
                x={860}
                style={{
                    fontSize: 10,
                    width: 60,
                    fill: '#455a64',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                }}
            />
        </g>
    );
}
