import { InputHTMLAttributes } from 'react';
import { classNames, formatToEuros, removeNonNumeric } from '../utils';

const FormattedInput = (props: InputHTMLAttributes<HTMLInputElement>) => {
    const { value, onChange, ...rest } = props;
    return (
        <input
            value={formatToEuros(+(value || 0))}
            onChange={(e) => {
                // some extra stuff is needed because we have to move around the cursor manually because the length changes after formatting
                const numericValue = +removeNonNumeric(e.target.value);
                const newFormattedValue = formatToEuros(numericValue);
                const caret = (e.target.selectionStart || 0) + (newFormattedValue.length - e.target.value.length);
                const element = e.target;
                window.requestAnimationFrame(() => {
                    element.selectionStart = caret;
                    element.selectionEnd = caret;
                });
                e.target.value = numericValue.toString();
                onChange && onChange(e);
            }}
            {...rest}
            className={classNames('text-right', props.className || '')}
        />
    );
};

export default FormattedInput;
