import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import client from '../client';
import useAuthStore from '../stores/auth';
import logo from '../assets/logo.svg';
import Loading from '../components/Loading';

export default function Login() {
    const [token, setToken] = useAuthStore((state) => [state.token, state.setToken], shallow);
    let navigate = useNavigate();
    const [userData, setUserData] = useState({
        email: '',
        password: '',
    });
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(false);
        setLoading(true);
        try {
            const res = await client.post('/token/', {
                ...userData,
            });
            setLoading(false);

            setToken(res.data.access, res.data.refresh);
            navigate('/');
        } catch (error) {
            setLoading(false);
            setError(true);
            console.log(error);
        }
    };

    if (token) {
        return <Navigate to="/" replace />;
    }

    return (
        <>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img className="mx-auto h-16 w-auto" src={logo} alt="Workflow" />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" action="#" onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={userData.email}
                                        onChange={(e) => {
                                            setUserData((state) => ({
                                                ...state,
                                                [e.target.name]: e.target.value,
                                            }));
                                        }}
                                        autoComplete="email"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-ru-teal focus:border-ru-teal sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={userData.password}
                                        onChange={(e) => {
                                            setUserData((state) => ({
                                                ...state,
                                                [e.target.name]: e.target.value,
                                            }));
                                        }}
                                        autoComplete="current-password"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-ru-teal focus:border-ru-teal sm:text-sm"
                                    />
                                </div>
                            </div>

                            {error ? (
                                <div className="flex items-center justify-between">
                                    <p className="mt-2 text-sm text-red-600" id="name-error">
                                        Email Address and/or Password are not valid
                                    </p>
                                </div>
                            ) : null}

                            <div>
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-ru-teal hover:bg-ru-teal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ru-teal"
                                >
                                    {loading ? <Loading size="small" /> : 'Sign in'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
