import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import { toPng } from 'html-to-image';
import GapAnalysisChart from '../components/GapAnalysisChart';
import { dateHasInvoiceData, formatDate, isDateInForecast, toLocaleYearAndMonth, utcDateFromString } from '../utils';
import Toggle from '../components/Toggle';
import GapAnalysisTimeline from '../components/GapAnalysisTimeline';
import { createBarData } from './ForecastEdit';

interface GapAnalysisChartDataContextType {
    useActualsData?: boolean;
    allMonths?: any;
    monthOptions?: any;
    selectedStartDate?: any;
    selectedEndDate?: any;
    selectedForecastOptions?: any;
    selectedForecast?: any;
    setSelectedStartDate?: any;
    setSelectedEndDate?: any;
    setSelectedForecast?: any;
    budgetForecast?: any;
    setBudgetForecast?: any;
    actualsShowableSelected?: any;
    actualsShowableBudget?: any;
}

export const GapAnalysisChartDataContext = createContext<GapAnalysisChartDataContextType>({});

export default function Analysis() {
    const ref = useRef<HTMLDivElement>(null);
    const { data: forecasts = [] } = useSWR(`/v1/forecast/`);
    /* ...rest assigned but never used */
    /*const [budgetForecast, ...rest] = forecasts;*/
    const [selectedForecast, setSelectedForecast] = useState<any>(null);
    const [budgetForecast, setBudgetForecast] = useState<any>(null);

    const { data: budgetForecastChartData = [] } = useSWR(() =>
        budgetForecast ? `/v1/forecast/${budgetForecast.id}/charts/?chart_id=1` : null,
    );
    const { data: selectedForecastChartData = [] } = useSWR(() =>
        selectedForecast ? `/v1/forecast/${selectedForecast.id}/charts/?chart_id=1` : null,
    );
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');
    const [showCategoryForecasts, setShowcategoryForecasts] = useState(false);
    const [useActualsData, setUseActualsData] = useState(false);

    const forecastOptions = useMemo(() => {
        return forecasts.filter((fo: any) => fo.use_product_categories === false);
    }, [forecasts]);

    const categoryForecastOptions = useMemo(() => {
        return forecasts.filter((fo: any) => fo.use_product_categories === true);
    }, [forecasts]);

    const selectedForecastOptions = useMemo(() => {
        return showCategoryForecasts ? categoryForecastOptions : forecastOptions;
    }, [showCategoryForecasts, categoryForecastOptions, forecastOptions]);

    useEffect(() => {
        if (forecastOptions.length && !categoryForecastOptions.length) {
            setShowcategoryForecasts(false);
        } else if (!forecastOptions.length && categoryForecastOptions.length) {
            setShowcategoryForecasts(true);
        }
    }, [forecastOptions, categoryForecastOptions]);

    useEffect(() => {
        if (selectedForecastOptions && selectedForecastOptions.length) {
            setSelectedForecast(selectedForecastOptions[1]);
            setBudgetForecast(selectedForecastOptions[0]);
        }
    }, [selectedForecastOptions]);

    const allMonths = useMemo(() => {
        if (!selectedForecast || !budgetForecast) return [];
        const min =
            selectedForecast.start_date < budgetForecast.start_date
                ? selectedForecast.start_date
                : budgetForecast.start_date;
        const max =
            selectedForecast.end_date < budgetForecast.end_date ? budgetForecast.end_date : selectedForecast.end_date;

        if (!min || !max) return [];

        const options = [];
        const [minYear, minMonth] = min.split('-');
        const [maxYear, maxMonth] = max.split('-');
        const minDateInMonths = minYear * 12 + +minMonth;
        const maxDateInMonths = maxYear * 12 + +maxMonth;

        for (let i = minDateInMonths; i <= maxDateInMonths; i++) {
            options.push({
                value: i,
                label: formatDate(new Date(Date.UTC(Math.floor(i / 12), (i % 12) - 1)), 'm-y', true), // -1 because months start with zero index
                date: new Date(Date.UTC(Math.floor(i / 12), (i % 12) - 1)),
            });
        }
        return options;
    }, [selectedForecast, budgetForecast]);

    const monthOptions = useMemo(() => {
        if (!selectedForecast || !budgetForecast) return [];
        const minDate = getMinOptionDate(selectedForecast.start_date, budgetForecast.start_date, useActualsData);

        const maxDate =
            selectedForecast.end_date > budgetForecast.end_date ? budgetForecast.end_date : selectedForecast.end_date;

        const options = allMonths.filter((m) => {
            return m.date >= utcDateFromString(minDate) && m.date <= utcDateFromString(maxDate);
        });
        return options;
    }, [selectedForecast, budgetForecast, allMonths, useActualsData]);

    useEffect(() => {
        if (monthOptions.length) {
            setSelectedStartDate(monthOptions[0].value.toString());
            setSelectedEndDate(monthOptions[monthOptions.length - 1].value.toString());
        }
    }, [monthOptions]);

    const [areActualsShowable, actualsShowableSelected, actualsShowableBudget] = useMemo(() => {
        const actualsShowableSelected = calculateIfActualsShowable(
            selectedForecast,
            selectedForecastChartData,
            allMonths,
        );
        const actualsShowableBudget = calculateIfActualsShowable(budgetForecast, budgetForecastChartData, allMonths);
        return [actualsShowableSelected || actualsShowableBudget, actualsShowableSelected, actualsShowableBudget];
    }, [selectedForecast, budgetForecast, selectedForecastChartData, budgetForecastChartData, allMonths]);

    useEffect(() => {
        if (!areActualsShowable && useActualsData) {
            setUseActualsData(false);
        }
    }, [areActualsShowable, useActualsData]);

    const filteredSelectedForecastData = useMemo(() => {
        if (selectedStartDate && selectedEndDate && selectedForecastChartData?.bar_data) {
            return selectedForecastChartData?.bar_data?.filter((d: any) => {
                const [currentYear, currentMonth] = d.interval_date.split('-');
                const currentDateInMonths = currentYear * 12 + +currentMonth;
                return currentDateInMonths >= +selectedStartDate && currentDateInMonths <= +selectedEndDate;
            });
        } else {
            return [];
        }
    }, [selectedStartDate, selectedEndDate, selectedForecastChartData]);

    const filteredBudgetForecastData = useMemo(() => {
        if (selectedStartDate && selectedEndDate && budgetForecastChartData?.bar_data) {
            return budgetForecastChartData?.bar_data?.filter((d: any) => {
                const [currentYear, currentMonth] = d.interval_date.split('-');
                const currentDateInMonths = currentYear * 12 + +currentMonth;
                return currentDateInMonths >= +selectedStartDate && currentDateInMonths <= +selectedEndDate;
            });
        } else {
            return [];
        }
    }, [selectedStartDate, selectedEndDate, budgetForecastChartData]);

    const onExportClick = useCallback(() => {
        if (ref.current === null) {
            return;
        }

        toPng(ref.current, {
            cacheBust: true,
            backgroundColor: '#fff',
            height: ref.current.clientHeight + 50,
        })
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = `gap-analysis-${budgetForecast.name}-${selectedForecast.name}-${formatDate(
                    new Date(),
                    'y-m-d-hms',
                )}.png`;
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    }, [ref, budgetForecast?.name, selectedForecast?.name]);

    if (!budgetForecast) return null;

    return (
        <div className="py-2">
            <div className="px-4 sm:px-6 lg:px-8 ">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">Gap Analysis</h1>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <button
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-ru-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-ru-blue focus:outline-none focus:ring-2 focus:ring-ru-teal focus:ring-offset-2 sm:w-auto"
                            onClick={onExportClick}
                        >
                            Download Chart
                        </button>
                    </div>
                </div>
                <div className="flex h-5 items-center justify-between mt-8 ml-1">
                    {forecastOptions.length && categoryForecastOptions.length ? (
                        <div className="flex gap-2">
                            <label className="block text-sm font-medium text-gray-700">Use Product Forecasts</label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <Toggle
                                    value={showCategoryForecasts}
                                    onChange={() => setShowcategoryForecasts((state) => !state)}
                                />
                            </div>
                            <label className="block text-sm font-medium text-gray-700">
                                Use Product Category Forecasts
                            </label>
                        </div>
                    ) : null}
                    <div className="flex gap-2">
                        <label className="block text-sm font-medium text-gray-700">Exclude actuals</label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <Toggle
                                value={useActualsData}
                                onChange={() => setUseActualsData((state) => !state)}
                                disabled={!areActualsShowable}
                            />
                        </div>
                        <label className="block text-sm font-medium text-gray-700">Include actuals</label>
                    </div>
                </div>
                <GapAnalysisChartDataContext.Provider
                    value={{
                        useActualsData,
                        allMonths,
                        monthOptions,
                        selectedStartDate,
                        selectedEndDate,
                        selectedForecastOptions,
                        selectedForecast,
                        setSelectedStartDate,
                        setSelectedEndDate,
                        setSelectedForecast,
                        budgetForecast,
                        setBudgetForecast,
                        actualsShowableSelected,
                        actualsShowableBudget,
                    }}
                >
                    <GapAnalysisTimeline />
                </GapAnalysisChartDataContext.Provider>
            </div>
            <div className="mt-10">
                <div className="px-2" ref={ref}>
                    {monthOptions.length ? (
                        <GapAnalysisChart
                            selectedForecastName={selectedForecast.name}
                            budgetForecastName={budgetForecast.name}
                            forecastData={filteredSelectedForecastData || []}
                            budgetData={filteredBudgetForecastData || []}
                            isCategory={budgetForecast.use_product_categories}
                            useActuals={useActualsData}
                        />
                    ) : (
                        <p className="block text-center pt-6 text-lg font-medium text-gray-700">
                            No matching timeframe between the forecasts
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}
function getMinOptionDate(selectedForecastStart: any, budgetForecastStart: any, useActualsData: boolean) {
    if (useActualsData) {
        //if using actuals (invoice) data, return the earlier date (that's as far as we can go back)
        return selectedForecastStart > budgetForecastStart ? budgetForecastStart : selectedForecastStart;
    } else {
        //if not using actuals data, return the later date to find the exact overlap of the 2 forecasts
        return selectedForecastStart > budgetForecastStart ? selectedForecastStart : budgetForecastStart;
    }
}

function calculateIfActualsShowable(forecast: any, chartData: { bar_data: any[] | undefined }, allMonths: any[]) {
    const invoiceData = createBarData(chartData?.bar_data, 'invoice_amount', false, null, false);

    const actualsShowable =
        allMonths.every((period: any) => {
            const isInForecast = isDateInForecast(forecast, period);
            return (!isInForecast && dateHasInvoiceData(invoiceData, period)) || isInForecast;
        }) && // only show actuals if it makes sense, it does not if there aren't any months without forecast values
        allMonths.some((period: any) => {
            const isInForecast = isDateInForecast(forecast, period);
            return !isInForecast && dateHasInvoiceData(invoiceData, period);
        });

    return actualsShowable;
}
