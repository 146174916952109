import { Outlet, NavLink } from 'react-router-dom';
import { classNames } from '../utils';
import useSWR from 'swr';

const links = [
    {
        title: 'Primary List',
        href: '',
    },
    { title: 'Not Matched', href: 'not-matched', showCountLabel: 'akas' },
];

export default function Customers() {
    const { data: akas = [] } = useSWR(`/v1/clientakas?is_matched=false`);
    const counts: {
        [key: string]: number;
    } = {
        akas: akas.length,
    };

    return (
        <>
            <div className="py-2">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Customers</h1>
                        </div>
                    </div>
                    <div className="block mb-6">
                        <div className="border-b border-gray-200">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                {links.map((link, index) => (
                                    <NavLink
                                        key={index}
                                        to={link.href}
                                        end
                                        className={({ isActive }) =>
                                            classNames(
                                                isActive
                                                    ? 'border-ru-blue text-gray-700'
                                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer',
                                            )
                                        }
                                    >
                                        {link.title}
                                        {link.showCountLabel && counts[link.showCountLabel] ? (
                                            <span
                                                className={classNames(
                                                    'bg-ru-blue text-white ml-2 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
                                                )}
                                            >
                                                {counts[link.showCountLabel]}
                                            </span>
                                        ) : null}
                                    </NavLink>
                                ))}
                            </nav>
                        </div>
                    </div>
                    <Outlet></Outlet>
                </div>
            </div>
        </>
    );
}
