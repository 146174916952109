import { Routes, Route } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import Layout from './screens/Layout';
import Login from './screens/Login';
import { SWRConfig } from 'swr';
import client from './client';

function App() {
    return (
        <SWRConfig
            value={{
                fetcher: (url) => client.get(url).then((res) => res.data),
            }}
        >
            <div className="App h-full">
                <Routes>
                    <Route
                        path="*"
                        element={
                            <RequireAuth>
                                <Layout />
                            </RequireAuth>
                        }
                    />
                    <Route path="login" element={<Login />} />
                </Routes>
            </div>
        </SWRConfig>
    );
}

export default App;
