import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { ChartBarIcon, CollectionIcon, XIcon, PresentationChartBarIcon, CalendarIcon } from '@heroicons/react/outline';
import { BriefcaseIcon, ChevronLeftIcon, ChevronRightIcon, TableIcon } from '@heroicons/react/solid';
import useLayoutStore from '../stores/layout';
import shallow from 'zustand/shallow';
import { classNames } from '../utils';
import logo from '../assets/logo.svg';
import logo_s from '../assets/logo_s.svg';
import useSWR from 'swr';
import useAuthStore from '../stores/auth';

const navigation = [
    /*{ name: 'Dashboard', to: '/', icon: HomeIcon, current: true },*/
    { name: 'Forecasts', to: 'forecasts', icon: ChartBarIcon },
    { name: 'Analysis', to: 'analysis', icon: PresentationChartBarIcon },
    {
        name: 'Dimensions',
        icon: TableIcon,
        to: '',
        children: [
            { name: 'Products', to: 'products', icon: CollectionIcon },
            { name: 'Customers', to: 'customers', icon: BriefcaseIcon },
        ],
    },
];

export default function Sidebar() {
    const [selectedAccount] = useAuthStore((state) => [state.selectedAccount], shallow);
    const { data: accountData } = useSWR(`/v1/accounts/${selectedAccount}/`);
    const [setSidebarOpen, sidebarOpen] = useLayoutStore((state) => [state.setSidebarOpen, state.sideBarOpen], shallow);
    const [setSidebarCollapsed, sidebarCollapsed] = useLayoutStore(
        (state) => [state.setSidebarCollapsed, state.sideBarCollapsed],
        shallow,
    );

    const importInfo = (
        <>
            <div className="text-gray-600 text-sm font-medium">Last Imports</div>
            <div className="text-xs text-gray-500 mt-2">Actuals thru {accountData?.formatted_max_invoice_date}</div>
            <div className="text-xs text-gray-500 mt-2">Orders thru {accountData?.formatted_max_order_date}</div>
            <div className="text-xs text-gray-500 mt-2">
                Opportunities thru {accountData?.formatted_max_opportunity_date}
            </div>
        </>
    );

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={() => {}}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(!sidebarOpen)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink-0 flex items-center px-4">
                                <img className="h-12 w-auto" src={logo} alt="Workflow" />
                            </div>
                            <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                <nav className="px-2 space-y-1">
                                    {navigation.map((item) => {
                                        return item?.children && item?.children.length > 0 ? (
                                            <div
                                                key={item.name}
                                                className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"
                                            >
                                                <item.icon
                                                    className="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                                {item.children.map((childItem) => {
                                                    return (
                                                        <NavLink
                                                            key={childItem.name}
                                                            to={childItem.to}
                                                            className={({ isActive }) => {
                                                                return classNames(
                                                                    isActive
                                                                        ? 'bg-gray-100 text-gray-900'
                                                                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                                    'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                                                                );
                                                            }}
                                                        >
                                                            <childItem.icon
                                                                className="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"
                                                                aria-hidden="true"
                                                            />
                                                            {childItem.name + 'aa'}
                                                        </NavLink>
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            <NavLink
                                                key={item.name}
                                                to={item.to}
                                                className={({ isActive }) => {
                                                    return classNames(
                                                        isActive
                                                            ? 'bg-gray-100 text-gray-900'
                                                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                        'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                                                    );
                                                }}
                                            >
                                                <item.icon
                                                    className="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                                {item.name + 'sssdd'}
                                            </NavLink>
                                        );
                                    })}
                                </nav>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            <div
                className={classNames(
                    sidebarCollapsed ? 'md:w-16' : 'md:w-64 pl-2',
                    'hidden md:flex md:flex-col flex-grow md:flex-shrink md:fixed md:h-full',
                )}
            >
                <div className="flex-1">
                    <div className="flex flex-col border-r border-gray-200 pt-5 bg-white h-full">
                        <div className="flex-1 flex flex-col">
                            <div
                                className={classNames(
                                    sidebarCollapsed ? 'justify-center' : '',
                                    'flex items-center flex-shrink-0 px-4',
                                )}
                            >
                                <img className="h-12 w-auto" src={sidebarCollapsed ? logo_s : logo} alt="RevsUp" />
                            </div>
                            <div className="mt-5 flex flex-col flex-grow">
                                <nav className="flex-1 px-2 pb-4 space-y-1">
                                    {navigation.map((item) => {
                                        return item?.children && item?.children.length > 0 ? (
                                            <div
                                                key={item.name}
                                                className={classNames(
                                                    sidebarCollapsed ? 'bg-gray-200 rounded-md p-1' : '',
                                                )}
                                            >
                                                <div
                                                    key={item.name}
                                                    className={classNames(
                                                        sidebarCollapsed
                                                            ? 'justify-center border-solid border-gray-400 border-b-2 rounded-none mb-2'
                                                            : '',
                                                        'text-gray-600 group flex items-center px-2 py-2 text-base font-medium rounded-md',
                                                    )}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            sidebarCollapsed ? '' : 'mr-3',
                                                            'text-gray-400 flex-shrink-0 h-6 w-6',
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {sidebarCollapsed ? null : item.name}
                                                </div>
                                                {item.children.map((childItem) => {
                                                    return (
                                                        <NavLink
                                                            key={childItem.name}
                                                            to={childItem.to}
                                                            className={({ isActive }) => {
                                                                return classNames(
                                                                    isActive
                                                                        ? 'bg-gray-100 text-gray-900'
                                                                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                                    sidebarCollapsed
                                                                        ? 'justify-center px-1 py-1'
                                                                        : 'pl-6 px-2 py-2 ',
                                                                    'group flex items-center text-base font-medium rounded-md',
                                                                );
                                                            }}
                                                        >
                                                            <childItem.icon
                                                                className={classNames(
                                                                    sidebarCollapsed ? '' : 'mr-3',
                                                                    'text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6',
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                            {sidebarCollapsed ? null : childItem.name}
                                                        </NavLink>
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            <NavLink
                                                key={item.name}
                                                to={item.to}
                                                className={({ isActive }) => {
                                                    return classNames(
                                                        isActive
                                                            ? 'bg-gray-100 text-gray-900'
                                                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                        sidebarCollapsed ? 'justify-center' : '',
                                                        'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                                                    );
                                                }}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        sidebarCollapsed ? '' : 'mr-3',
                                                        'text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6',
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                {sidebarCollapsed ? null : item.name}
                                            </NavLink>
                                        );
                                    })}
                                    <div className="absolute top-1/2 left-full -translate-y-1/2 rounded-tr-full w-4 h-8 rounded-br-full p-1 flex justify-end items-center bg-white border-br-gray-200 border-tr-gray-200 border-l-2 border-l-white border -ml-[2px]">
                                        {sidebarCollapsed ? (
                                            <ChevronRightIcon
                                                className="text-gray-400 hover:text-gray-500 cursor-pointer w-5 h-5 -m-r-2"
                                                onClick={() => setSidebarCollapsed(false)}
                                            />
                                        ) : (
                                            <ChevronLeftIcon
                                                className="text-gray-400 hover:text-gray-500 cursor-pointer w-5 h-5 -m-r-2"
                                                onClick={() => {
                                                    setSidebarCollapsed(true);
                                                }}
                                            />
                                        )}
                                    </div>
                                </nav>
                            </div>
                        </div>
                        {sidebarCollapsed ? (
                            <div className="flex flex-col items-center p-5 group relative">
                                <CalendarIcon className="h-6 w-6 text-gray-400" />
                                <div className="p-5 pointer-events-none absolute -top-20 left-12 w-max opacity-0 transition-opacity group-hover:opacity-100 bg-white rounded shadow">
                                    {importInfo}
                                </div>
                            </div>
                        ) : (
                            <div className="pb-5 px-2">{importInfo}</div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
