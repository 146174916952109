import { useContext } from 'react';
import RangeInput from '../components/RangeInput';
import AutoComplete from '../components/AutoComplete';
import ForecastPeriodBar from './ForecastPeriodBar';
import { GapAnalysisChartDataContext } from '../screens/Analysis';
import { StarIcon } from '@heroicons/react/solid';

export default function GapAnalysisTimeline() {
    const {
        allMonths,
        monthOptions,
        selectedStartDate,
        selectedEndDate,
        selectedForecastOptions,
        selectedForecast,
        setSelectedStartDate,
        setSelectedEndDate,
        setSelectedForecast,
        budgetForecast,
        setBudgetForecast,
        actualsShowableSelected,
        actualsShowableBudget,
    } = useContext(GapAnalysisChartDataContext);

    return (
        <div className="mt-4 flex flex-col bg-white shadow sm:rounded-lg p-4 pb-6">
            <label className="block text-sm font-medium text-gray-700">Selected Forecast 1</label>
            <div className="grid grid-cols-12 gap-x-3">
                <div className="col-span-2">
                    <AutoComplete
                        list={selectedForecastOptions}
                        current={[selectedForecast]}
                        onChange={(newValue) => {
                            setSelectedStartDate('');
                            setSelectedEndDate('');
                            setSelectedForecast(newValue);
                        }}
                        itemLabelAccessor={(forecast) => forecast?.name}
                        highlightedItemChecker={(forecast) => forecast.is_budget}
                        itemHighlighter={<StarIcon className="ml-1 text-ru-green hover:text-ru-greeen h-5 w-5" />}
                    />
                </div>
                <div className="col-span-10 flex items-center">
                    <ForecastPeriodBar
                        forecastA={selectedForecast}
                        forecastB={budgetForecast}
                        alignment="top"
                        actualsShowable={actualsShowableSelected}
                    />
                </div>
            </div>
            <div className="my-4 grid grid-cols-12 gap-x-3 ">
                <>
                    <div className="col-span-2 flex items-center">
                        <div className="text-sm text-gray-700">Set start and end period</div>
                    </div>
                    <div className="col-span-10 flex h-14 items-center">
                        {monthOptions.length && selectedStartDate && selectedEndDate ? (
                            <RangeInput
                                widthRatio={(monthOptions.length - 1) / allMonths.length}
                                startOffset={
                                    allMonths.findIndex((m: any) => m.value === monthOptions[0].value) /
                                        allMonths.length +
                                    0.5 / allMonths.length
                                }
                                options={monthOptions}
                                values={[+selectedStartDate, +selectedEndDate]}
                                onChange={(values: any) => {
                                    setSelectedStartDate(values[0]);
                                    setSelectedEndDate(values[1]);
                                }}
                            />
                        ) : null}
                    </div>
                </>
            </div>
            <label htmlFor="budgetName" className="block text-sm font-medium text-gray-700">
                Selected Forecast 2
            </label>
            <div className="grid grid-cols-12 gap-x-3">
                <div className="col-span-2">
                    <AutoComplete
                        list={selectedForecastOptions}
                        current={[budgetForecast]}
                        onChange={(newValue) => {
                            setSelectedStartDate('');
                            setSelectedEndDate('');
                            setBudgetForecast(newValue);
                        }}
                        itemLabelAccessor={(forecast) => forecast?.name}
                        highlightedItemChecker={(forecast) => forecast.is_budget}
                        itemHighlighter={<StarIcon className="ml-1 text-ru-green hover:text-ru-greeen h-5 w-5" />}
                    />
                </div>
                <div className="col-span-10 flex items-center">
                    <ForecastPeriodBar
                        forecastA={budgetForecast}
                        forecastB={selectedForecast}
                        alignment="bottom"
                        actualsShowable={actualsShowableBudget}
                    />
                </div>
            </div>
        </div>
    );
}
