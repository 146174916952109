import create from 'zustand';

type State = {
    sideBarOpen: boolean;
    sideBarCollapsed: boolean;
    setSidebarOpen: (isOpen: boolean) => void;
    setSidebarCollapsed: (isCollapsed: boolean) => void;
    breadcrumbOverride:
        | null
        | {
              to: string;
              label: string;
          }[];
    setBreadcrumbOverride: (crumbs: State['breadcrumbOverride']) => void;
};

const useLayoutStore = create<State>((set, get) => ({
    sideBarOpen: false,
    sideBarCollapsed: false,
    setSidebarOpen: (isOpen: boolean) => {
        set({ sideBarOpen: isOpen });
    },
    setSidebarCollapsed: (isCollapsed: boolean) => {
        set({ sideBarCollapsed: isCollapsed });
    },
    breadcrumbOverride: null,
    setBreadcrumbOverride: (crumbs) => {
        set({ breadcrumbOverride: crumbs });
    },
}));

export default useLayoutStore;
