import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { CheckIcon, MinusSmIcon, XIcon } from '@heroicons/react/outline';
import { PencilIcon } from '@heroicons/react/solid';

import client from '../client';
import { ClientAka, Client } from '../types';
import ConfirmationModal from './ConfirmationModal';
import AutoComplete from './AutoComplete';
import useLayoutStore from '../stores/layout';
import Loader from './Loader';

interface Merge {
    mergeToId: number;
    mergeToName: string;
    mergeFromId: number;
    mergeFromName: string;
}

export default function CustomerList() {
    const setCustomBreadcrumbs = useLayoutStore((state) => state.setBreadcrumbOverride);
    const { data: customers = [] } = useSWR(`/v1/clients/`);
    const [searchShownFor, setSearchShownFor] = useState(-1);
    const [selectedCustomer] = useState(null);
    const [akaToRemove, setAkaToRemove] = useState<ClientAka | null>(null);
    const [customerNameToEdit, setCustomerNameToEdit] = useState<number>(-1);
    const [editedCustomerName, setEditedCustomerName] = useState('');
    const [mergeDetails, setMergeDetails] = useState<Merge | null>(null);
    const [nameUpdateLoading, setNameUpdateLoading] = useState<boolean>(false);

    useEffect(() => {
        setCustomBreadcrumbs([
            { label: 'Customers', to: 'customers' },
            { label: 'Primary List ', to: '' },
        ]);
    }, [setCustomBreadcrumbs]);

    const removeAkaFromCustomer = async (aka: ClientAka) => {
        await client.patch(`/v1/clientakas/${aka.id}/`, {
            name: aka.name,
            client: null,
            account: aka.account,
        });
        mutate(`/v1/clients/`);
    };

    const mergeCustomers = async (merge: Merge) => {
        await client.post(`/v1/clients/${merge.mergeToId}/merge/`, {
            merge_from_client_id: merge.mergeFromId,
        });
        mutate(`/v1/clients/`);
    };

    const updateCustomerName = async (customer: Client) => {
        const { account, id, name } = customer;
        setNameUpdateLoading(true);
        await client.patch(`/v1/clients/${id}/`, {
            name: editedCustomerName,
            account,
        });
        await client.post(`/v1/clientakas/`, {
            name: editedCustomerName,
            account,
            client: id,
        });
        // add previous names as aka if not present already
        if (!customer.akas.find((aka) => aka.name === name)) {
            await client.post(`/v1/clientakas/`, {
                name,
                account,
                client: id,
            });
        }
        mutate(`/v1/clients/`);
        setNameUpdateLoading(false);
        setCustomerNameToEdit(-1);
        setEditedCustomerName('');
    };

    return (
        <div className="flex flex-col">
            <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <div className="bg-gray-50 sticky top-[6.8rem] z-10 flex border-b border-gray-300 -mt-1">
                            <div className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 w-1/2">
                                Customer Name
                            </div>
                            <div className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/2">
                                Also Known As
                            </div>
                        </div>
                        <table className="min-w-full">
                            <colgroup>
                                <col className="sm:w-1/2" />
                                <col className="sm:w-1/2" />
                            </colgroup>
                            <tbody className="bg-white">
                                {customers.map((customer: any, i: number) => (
                                    <tr key={customer.id} className={`group ${i % 2 === 0 ? undefined : 'bg-gray-50'}`}>
                                        <td className="flex whitespace-nowrap px-3 py-6 text-sm font-medium text-gray-900">
                                            <div className="flex flex-row self-start items-center cursor-default">
                                                {customer?.id === customerNameToEdit ? (
                                                    <div className="w-full flex items-center">
                                                        <div className="w-full flex rounded-md shadow-sm">
                                                            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                <input
                                                                    type="text"
                                                                    value={editedCustomerName}
                                                                    onChange={(e) =>
                                                                        setEditedCustomerName(e.target.value)
                                                                    }
                                                                    className="block w-full rounded-l-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ru-blue sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-600 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                                onClick={() => updateCustomerName(customer)}
                                                            >
                                                                {nameUpdateLoading ? (
                                                                    <Loader className="-ml-0.5 h-5 w-5 text-ru-blue" />
                                                                ) : (
                                                                    <CheckIcon
                                                                        aria-hidden="true"
                                                                        className="-ml-0.5 h-5 w-5 text-ru-blue"
                                                                    />
                                                                )}
                                                            </button>
                                                        </div>

                                                        <XIcon
                                                            className="h-4 w-4 cursor-pointer ml-2"
                                                            onClick={() => setCustomerNameToEdit(-1)}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="relative">
                                                        <div className="pl-2">{customer.name}</div>
                                                        <button
                                                            title="Edit name"
                                                            className="hidden group-hover:inline-flex absolute -right-0.5 -top-0.5 translate-x-full items-center justify-center h-5 w-5 ml-2 mb-1.5
                                                    text-xl font-medium focus:outline-none"
                                                            onClick={() => {
                                                                setCustomerNameToEdit(customer.id);
                                                                setEditedCustomerName(customer.name);
                                                            }}
                                                        >
                                                            <PencilIcon className="text-gray-600" />
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </td>

                                        <td className="px-3 py-6 text-sm text-gray-500 w-full">
                                            <div className="flex flex-row">
                                                <div className="flex flex-col ">
                                                    {customer.akas
                                                        .filter((aka: any) => aka.name !== customer.name)
                                                        .map((aka: any, idx: number, akas: any[]) => {
                                                            return (
                                                                <div
                                                                    key={idx}
                                                                    className="flex flex-row self-start items-center px-2 hover:bg-gray-100 rounded-md border border-transparent cursor-default"
                                                                >
                                                                    <div key={aka.id}>{aka.name}</div>
                                                                    <button
                                                                        title="Unmatch"
                                                                        className="hidden group-hover:inline-flex items-center justify-center rounded-md border border-transparent h-5 w-5 ml-2 
                                                            text-xl font-medium text-white shadow-sm bg-ru-teal focus:outline-none"
                                                                        onClick={() => {
                                                                            setAkaToRemove({
                                                                                ...aka,
                                                                                client: customer.id,
                                                                                account: customer.account,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <MinusSmIcon className="text-white" />
                                                                    </button>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                                <div className="ml-auto">
                                                    {searchShownFor === customer.id ? (
                                                        <div className="flex items-center">
                                                            <AutoComplete
                                                                label="Merge into"
                                                                list={customers.filter(
                                                                    (c: any) => c.id !== customer.id,
                                                                )}
                                                                current={[selectedCustomer]}
                                                                itemLabelAccessor={(item: any) => item?.name}
                                                                onChange={(mergeTarget: any) => {
                                                                    if (mergeTarget) {
                                                                        setMergeDetails({
                                                                            mergeFromId: customer.id,
                                                                            mergeFromName: customer.name,
                                                                            mergeToId: mergeTarget.id,
                                                                            mergeToName: mergeTarget.name,
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                            <XIcon
                                                                className="h-4 w-4 cursor-pointer ml-2"
                                                                onClick={() => setSearchShownFor(-1)}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="bg-ru-blue text-white ml-2 hidden rounded-md px-2.5 py-0.5 text-xs font-bold md:inline-block cursor-pointer"
                                                            onClick={() => setSearchShownFor(customer.id)}
                                                        >
                                                            MERGE
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ConfirmationModal
                title={`Are you sure you want to unmatch ${akaToRemove?.name} from ${
                    customers.find((c: any) => c.id === akaToRemove?.client)?.name
                }?`}
                isOpen={!!akaToRemove}
                closeModal={() => setAkaToRemove(null)}
                onConfirmation={() => {
                    if (akaToRemove) removeAkaFromCustomer(akaToRemove);
                    setAkaToRemove(null);
                }}
            />
            <ConfirmationModal
                title={`Are you sure you want to merge ${mergeDetails?.mergeFromName} into ${mergeDetails?.mergeToName}?.`}
                isOpen={!!mergeDetails}
                closeModal={() => setMergeDetails(null)}
                onConfirmation={() => {
                    if (mergeDetails) mergeCustomers(mergeDetails);
                    setMergeDetails(null);
                }}
            />
        </div>
    );
}
