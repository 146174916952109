import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { TrashIcon } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';
import client from '../client';
import useForecastStore from '../stores/forecasts';
import { classNames, formatDate } from '../utils';
import ConfirmationModal from '../components/ConfirmationModal';
import Loading from '../components/Loading';
import useForecasts from '../hooks/useForecasts';

export default function Forecasts() {
    const [forecastsToPoll, clearForescastFromPolling] = useForecastStore(
        (state) => [state.forecastsToPoll, state.clearForecastFromPolling],
        shallow,
    );
    const { forecasts, mutate } = useForecasts();
    const [forecastToDelete, setForecastToDelete] = useState<number | null>(null);

    useEffect(() => {
        const polling = setInterval(() => {
            mutate();
        }, 5000);
        forecastsToPoll.forEach((id) => {
            const forecast = forecasts.find((f) => f.id === id);

            if (forecast && (forecast?.status === 'editing' || forecast?.status === 'completed')) {
                clearForescastFromPolling(id);
            }
        });
        if (forecastsToPoll.length === 0) {
            clearInterval(polling);
        }
        return () => {
            clearInterval(polling);
        };
    }, [forecastsToPoll, forecasts, clearForescastFromPolling]);

    const deleteForecast = async (id: number) => {
        setForecastToDelete(null);
        await client.delete(`/v1/forecast/${id}`);
        mutate();
    };

    return (
        <>
            <div className="py-2">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Forecasts</h1>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <Link
                                to="/forecasts/new"
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-ru-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-ru-blue focus:outline-none focus:ring-2 focus:ring-ru-teal focus:ring-offset-2 sm:w-auto"
                            >
                                Create forecast
                            </Link>
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                ></th>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                >
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Created
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Created By
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Start Date
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    End Date
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Type
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Status
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {forecasts.map((forecast: any, i) => {
                                                const forecastLoading =
                                                    forecast.status === 'creating' || forecast.status === 'finishing';
                                                return (
                                                    <tr
                                                        key={forecast.id}
                                                        className={classNames(
                                                            i % 2 === 0 ? '' : 'bg-gray-50',
                                                            forecast.is_budget
                                                                ? 'border-2 border-ru-blue bg-ru-blue bg-opacity-75'
                                                                : '',
                                                        )}
                                                    >
                                                        <td className="whitespace-nowrap pl-2 py-1 text-sm text-gray-500">
                                                            {forecast.is_budget && (
                                                                <div title="Budget Forecast">
                                                                    <StarIcon className="ml-1 text-ru-green hover:text-ru-greeen h-5 w-5" />
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {forecast.status === 'editing' ? (
                                                                <Link
                                                                    to={`/forecasts/${forecast.id}/result`}
                                                                    className={
                                                                        forecastLoading
                                                                            ? 'text-ru-grey pointer-events-none'
                                                                            : 'text-ru-teal'
                                                                    }
                                                                >
                                                                    {forecast.name}
                                                                </Link>
                                                            ) : (
                                                                forecast.name
                                                            )}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            {formatDate(new Date(forecast.created_at))}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            {forecast.full_name}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            {forecast.start_date}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            {forecast.end_date}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm truncate text-gray-500">
                                                            {forecast.use_product_categories
                                                                ? 'Categories'
                                                                : 'Products'}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm capitalize text-gray-500">
                                                            {forecast.status}
                                                        </td>
                                                        <td
                                                            colSpan={forecastLoading ? 2 : 1}
                                                            className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6"
                                                        >
                                                            {forecastLoading ? (
                                                                <Loading size={'small'}></Loading>
                                                            ) : (
                                                                <Link
                                                                    to={`/forecasts/${forecast.id}/result`}
                                                                    className="text-ru-teal"
                                                                >
                                                                    View
                                                                    <span className="sr-only">, {forecast.name}</span>
                                                                </Link>
                                                            )}
                                                        </td>
                                                        {forecastLoading ? null : (
                                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
                                                                {forecast?.status !== 'completed' && (
                                                                    <Link
                                                                        to={`/forecasts/${forecast.id}/edit`}
                                                                        className="text-ru-teal hover:text-ru-teal"
                                                                    >
                                                                        Edit
                                                                        <span className="sr-only">
                                                                            , {forecast.name}
                                                                        </span>
                                                                    </Link>
                                                                )}
                                                            </td>
                                                        )}
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            <TrashIcon
                                                                className="mr-4 flex-shrink-0 h-4 w-4 cursor-pointer"
                                                                onClick={() => {
                                                                    setForecastToDelete(forecast.id);
                                                                }}
                                                            ></TrashIcon>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationModal
                title="Are you sure you want to delete this forecast?"
                isOpen={!!forecastToDelete}
                closeModal={() => setForecastToDelete(null)}
                onConfirmation={() => {
                    if (forecastToDelete) deleteForecast(forecastToDelete);
                }}
            />
        </>
    );
}
