import { useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { XIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

import AutoComplete from '../components/AutoComplete';
import Loading from '../components/Loading';
import { formatDate, formatToEuros, utcDateFromString } from '../utils';
import useLayoutStore from '../stores/layout';
import client from '../client';
import ForecastChart from '../components/ForecastChart';
import ForecastDetails from '../components/ForecastDetails';

export default function ForecastResult() {
    const { forecastId } = useParams();
    const [itemFilter, setItemFilter] = useState<string[]>([]);
    const [isGroupedByCategories, setIsGroupedByCategories] = useState<boolean>(false);
    const [categoriesCollapsed, setCategoriesCollapsed] = useState<string[]>([]);
    const setCustomBreadcrumbs = useLayoutStore((state) => state.setBreadcrumbOverride);

    const { data: chartData } = useSWR(`/v1/forecast/${forecastId}/charts/?chart_id=1`);
    const { data: forecastDetails = {} } = useSWR(`/v1/forecast/${forecastId}/`);
    const { data: products = [] } = useSWR(`/v1/forecast/${forecastId}/products/`);
    const { data: productCategories = [] } = useSWR(`/v1/forecast/${forecastId}/product-categories/`);

    useEffect(() => {
        setCustomBreadcrumbs([
            { label: 'Forecasts', to: 'forecasts' },
            { label: forecastDetails?.name || '', to: '' },
        ]);
    }, [forecastDetails, setCustomBreadcrumbs]);

    useEffect(() => {
        if (forecastDetails) {
            setIsGroupedByCategories(forecastDetails.use_product_categories);
        }
    }, [forecastDetails]);

    const itemList = useMemo(() => {
        return forecastDetails?.use_product_categories && forecastDetails?.status !== 'completed'
            ? productCategories
            : products;
    }, [forecastDetails, productCategories.length, products.length]);

    useEffect(() => {
        setItemFilter(itemList.map((d: any) => d.name));
    }, [itemList]);

    const categories = useMemo(() => {
        if (!products || (forecastDetails?.use_product_categories && forecastDetails?.status !== 'completed'))
            return [];

        const categoriesByName: Record<string, string[]> = {};
        products.forEach(({ category_1 = '', name = '' }) => {
            if (categoriesByName[category_1]) {
                if (!categoriesByName[category_1].includes(name)) {
                    categoriesByName[category_1].push(name);
                }
            } else {
                categoriesByName[category_1] = [name];
            }
        });
        return Array.from(Object.entries(categoriesByName), ([key, value]) => {
            return {
                category: key,
                products: value,
            };
        });
    }, [products, forecastDetails.use_product_categories, forecastDetails.status]);

    useEffect(() => {
        setCategoriesCollapsed(categories.map((c) => c.category));
    }, [categories.length]);

    const [invoiceAmount, orderAmount, opportunityAmount, unknownAmount, targetAmount] = useMemo(() => {
        return ['invoice_amount', 'order_amount', 'opportunity_amount', 'unknown_amount', 'target_amount'].map(
            (key) => {
                return createBarData(
                    chartData?.bar_data,
                    key,
                    itemFilter,
                    forecastDetails?.use_product_categories && forecastDetails?.status !== 'completed'
                        ? 'category'
                        : 'product_name',
                    key !== 'target_amount',
                );
            },
        );
    }, [chartData?.bar_data, itemFilter, forecastDetails.use_product_categories, forecastDetails.status]);

    const handleDownload = async () => {
        //hacky axios file download
        const res = await client({
            url: `/v1/forecast/${forecastId}/download/`,
            method: 'GET',
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `${forecastDetails.name}-${formatDate(new Date(forecastDetails.created_at), 'y-m-d-hms')}.csv`,
        );
        document.body.appendChild(link);
        link.click();
    };

    if (!forecastDetails) return null;

    return (
        <div className="py-2">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="bg-white shadow sm:rounded-lg">
                        <ForecastDetails details={forecastDetails}>
                            {forecastDetails?.status !== 'completed' && forecastDetails?.status !== 'finishing' && (
                                <Link
                                    to={`/forecasts/${forecastId}/edit/`}
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-400 px-4 py-1 text-sm font-medium text-white shadow-sm hover:bg-ru-blue focus:outline-none focus:ring-2 focus:ring-ru-teal focus:ring-offset-2 sm:w-auto"
                                >
                                    Edit
                                </Link>
                            )}
                            <button
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-400 px-4 py-1 text-sm font-medium text-white shadow-sm hover:bg-ru-blue focus:outline-none focus:ring-2 focus:ring-ru-teal focus:ring-offset-2 sm:w-auto"
                                onClick={handleDownload}
                            >
                                Download CSV
                            </button>
                        </ForecastDetails>
                    </div>
                </div>
                <div className="h-96 grid grid-cols-12 mt-6">
                    <div className="px-2 col-span-8">
                        {chartData && productCategories && products ? (
                            <ForecastChart
                                amountsList={[
                                    invoiceAmount,
                                    orderAmount,
                                    opportunityAmount,
                                    unknownAmount,
                                    targetAmount,
                                ]}
                                startDate={forecastDetails?.start_date}
                            />
                        ) : (
                            <div className="flex flex-row items-center justify-center mt-12">
                                <Loading size="large" />
                            </div>
                        )}
                    </div>
                    <div className="bg-white shadow sm:rounded-lg col-span-4 p-4 overflow-auto">
                        <AutoComplete
                            list={itemList.map((d: any) => d.name)}
                            current={itemFilter}
                            onChange={(newValue: string) => {
                                setItemFilter((state) => Array.from(new Set([...state, newValue])));
                            }}
                            label="Product Filter"
                        />

                        <div className="mt-1 flex flex-row space-x-1">
                            <button
                                type="button"
                                onClick={() => {
                                    setItemFilter(itemList.map((d: any) => d.name));
                                }}
                                className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-small rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                            >
                                Select All
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setItemFilter([]);
                                }}
                                className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-small rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                            >
                                Clear All
                            </button>
                        </div>
                        {forecastDetails?.status === 'completed' && (
                            <>
                                <div className="relative flex items-start my-2">
                                    <div className="flex items-center h-5 focus:outline-none">
                                        <input
                                            id="comments"
                                            aria-describedby="comments-description"
                                            name="comments"
                                            type="checkbox"
                                            checked={isGroupedByCategories}
                                            onChange={(e) => setIsGroupedByCategories(e.target.checked)}
                                            className="h-4 w-4 text-ru-teal border-gray-300 rounded focus:outline-none focus:ring-transparent"
                                        />
                                    </div>
                                    <div className="ml-1 text-sm">
                                        <label htmlFor="comments" className="text-gray-500">
                                            Show by Categories
                                        </label>
                                    </div>
                                </div>
                                {isGroupedByCategories && (
                                    <div className="mt-1 flex flex-row space-x-1">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setCategoriesCollapsed([]);
                                            }}
                                            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-small rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                                        >
                                            Open all
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setCategoriesCollapsed(categories.map((c) => c.category));
                                            }}
                                            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-small rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                                        >
                                            Collapse All
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                        <div className="-m1 mt-1">
                            {forecastDetails?.status === 'completed' && isGroupedByCategories
                                ? categories.map((c) => {
                                      const isAllSelected = c.products.every((p) => itemFilter.includes(p));
                                      return (
                                          <div className="my-2" key={c.category}>
                                              <div className="flex items-center">
                                                  <input
                                                      id="comments"
                                                      aria-describedby="comments-description"
                                                      name="comments"
                                                      type="checkbox"
                                                      checked={isAllSelected}
                                                      onChange={(e) => {
                                                          if (e.target.checked) {
                                                              setItemFilter((state) =>
                                                                  Array.from(new Set([...state, ...c.products])),
                                                              );
                                                          } else {
                                                              setItemFilter((state) =>
                                                                  state.filter((p) => !c.products.includes(p)),
                                                              );
                                                          }
                                                      }}
                                                      className="h-4 w-4 mr-1 text-ru-blue border-gray-300 rounded focus:outline-none focus:ring-transparent"
                                                  />
                                                  {c.category}

                                                  {isAllSelected ? (
                                                      categoriesCollapsed.includes(c.category) ? (
                                                          <ChevronDownIcon
                                                              className="text-gray-600 h-4 w-4 ml-auto mr-3 cursor-pointer"
                                                              onClick={() => {
                                                                  setCategoriesCollapsed((state) =>
                                                                      state.filter((cat) => cat !== c.category),
                                                                  );
                                                              }}
                                                          />
                                                      ) : (
                                                          <ChevronUpIcon
                                                              className="text-gray-600 h-4 w-4 ml-auto mr-3 cursor-pointer"
                                                              onClick={() => {
                                                                  setCategoriesCollapsed((state) => [
                                                                      ...state,
                                                                      c.category,
                                                                  ]);
                                                              }}
                                                          />
                                                      )
                                                  ) : null}
                                              </div>
                                              {!categoriesCollapsed.includes(c.category)
                                                  ? itemFilter
                                                        .filter((p) => c.products.includes(p))
                                                        .map((p) => {
                                                            return (
                                                                <div
                                                                    key={p}
                                                                    className="inline-flex items-center m-1 px-2.5 py-0.5 rounded-md text-xs font-medium bg-ru-blue text-gray-600"
                                                                >
                                                                    {p}
                                                                    <button
                                                                        onClick={() => {
                                                                            setItemFilter((state) =>
                                                                                state.filter((e) => e !== p),
                                                                            );
                                                                        }}
                                                                        type="button"
                                                                        className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-ru-grey hover:bg-ru-teal "
                                                                    >
                                                                        <XIcon />
                                                                    </button>
                                                                </div>
                                                            );
                                                        })
                                                  : null}
                                          </div>
                                      );
                                  })
                                : itemFilter.map((p) => {
                                      return (
                                          <div
                                              key={p}
                                              className="inline-flex items-center m-1 px-2.5 py-0.5 rounded-md text-xs font-medium bg-ru-blue text-gray-600"
                                          >
                                              {p}
                                              <button
                                                  onClick={() => {
                                                      setItemFilter((state) => state.filter((e) => e !== p));
                                                  }}
                                                  type="button"
                                                  className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-ru-grey hover:bg-ru-teal "
                                              >
                                                  <XIcon />
                                              </button>
                                          </div>
                                      );
                                  })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const createBarData = (
    array: any[] = [],
    key: string,
    filter: string[] = [],
    filterKey: string,
    labels: boolean = false,
) => {
    return array.map((d: any) => {
        const amount = d.amounts.reduce((sum: any, current: any) => {
            if (filter.includes(current[filterKey])) {
                return sum + (+current[key] || 0);
            } else {
                return sum;
            }
        }, 0);
        return {
            date: utcDateFromString(d.interval_date),
            amount,
            ...(labels
                ? {
                      label: `${DICTIONARY[key]}: ${formatToEuros(amount, {
                          minimumFractionDigits: 2,
                      })}`,
                  }
                : {}),
        };
    });
};

const DICTIONARY: Record<string, string> = {
    invoice_amount: 'Realized revenue',
    order_amount: 'Contracted revenue',
    opportunity_amount: 'Opportunity revenue',
    unknown_amount: 'Unidentified revenue',
    total_amount: 'Target revenue',
};
