import {
    VictoryChart,
    VictoryTheme,
    VictoryVoronoiContainer,
    VictoryTooltip,
    VictoryAxis,
    VictoryStack,
    VictoryBar,
    VictoryLine,
    VictoryLegend,
    VictoryLabel,
} from 'victory';
import { formatDate, formatToEuros } from '../utils';

interface ForecastChartProps {
    amountsList: any;
    startDate?: string;
}

export default function ForecastChart({ amountsList, startDate = '' }: ForecastChartProps) {
    const [invoiceAmount, orderAmount, opportunityAmount, unknownAmount, targetAmount] = amountsList;
    return (
        <VictoryChart
            domainPadding={20}
            theme={VictoryTheme.material}
            width={750}
            height={300}
            containerComponent={
                <VictoryVoronoiContainer
                    style={{
                        height: 'auto',
                    }}
                    labels={({ datum }) => {
                        return `Target Revenue: ${formatToEuros(datum.amount, {
                            minimumFractionDigits: 2,
                        })}`;
                    }}
                    labelComponent={
                        <VictoryTooltip
                            flyoutStyle={{
                                fill: '#fff',
                                display: (d) => {
                                    if (d.datum.amount === 0) return 'none';

                                    return 'block';
                                },
                            }}
                            flyoutPadding={10}
                            pointerLength={5}
                            style={{
                                display: (d) => {
                                    if (d.datum.amount === 0) return 'none';

                                    return 'block';
                                },
                                fontSize: 10,
                            }}
                        />
                    }
                />
            }
        >
            <VictoryAxis
                tickFormat={(t) => {
                    return formatDate(t, 'm-y');
                }}
                tickLabelComponent={
                    <VictoryLabel
                        angle={invoiceAmount.length >= 14 ? 45 : 0}
                        textAnchor={invoiceAmount.length >= 14 ? 'start' : 'middle'}
                        verticalAnchor={invoiceAmount.length >= 14 ? 'end' : 'middle'}
                    />
                }
                tickValues={invoiceAmount.map((d: any) => d.date)}
                style={{ tickLabels: { fontSize: 10 } }}
            />
            <VictoryAxis
                dependentAxis
                tickFormat={(t, i) => {
                    if (t < 1) return (i + 1) * 100;
                    return (
                        formatToEuros(t / 1000, {
                            maximumFractionDigits: 0,
                        }) + 'k'
                    );
                }} // dots here
            />
            <VictoryStack colorScale={['#7A7A7A', '#1A4A43', '#81CBDD', '#CDF46E']}>
                {[invoiceAmount, orderAmount, opportunityAmount, unknownAmount].map((data, i) => {
                    return (
                        <VictoryBar
                            key={i}
                            alignment="middle"
                            data={data}
                            x={(d) => d.date.getTime()}
                            y="amount"
                            labelComponent={
                                <VictoryTooltip
                                    flyoutStyle={{
                                        fill: '#fff',
                                        display: (d) => {
                                            if (d.datum.amount === 0) return 'none';

                                            return 'block';
                                        },
                                    }}
                                    flyoutPadding={10}
                                    pointerLength={5}
                                    style={{
                                        display: (d) => {
                                            if (d.datum.amount === 0) return 'none';

                                            return 'block';
                                        },
                                        textTransform: 'capitalize',
                                        fontSize: 10,
                                    }}
                                />
                            }
                        />
                    );
                })}
            </VictoryStack>
            <VictoryLine
                style={{
                    data: { stroke: '#FFA838' },
                    parent: { border: '2px solid #ccc' },
                    labels: { display: 'none' },
                }}
                data={
                    targetAmount.every((ta: any) => ta.amount === 0) && startDate
                        ? []
                        : targetAmount.filter((ta: any) => {
                              return ta.date >= new Date(startDate);
                          })
                }
                x={(d) => new Date(d.date).getTime()}
                y="amount"
                interpolation="catmullRom"
            />
            <VictoryLegend
                x={30}
                y={285}
                groupComponent={<g style={{ margin: 'auto' }} />}
                orientation="horizontal"
                gutter={20}
                data={[
                    { name: 'Realized revenue', symbol: { fill: '#7A7A7A', type: 'square' } },
                    { name: 'Contracted revenue', symbol: { fill: '#1A4A43', type: 'square' } },
                    {
                        name: 'Opportunity revenue',
                        symbol: { fill: '#81CBDD', type: 'square' },
                    },
                    {
                        name: 'Unidentified revenue',
                        symbol: { fill: '#CDF46E', type: 'square' },
                    },
                    { name: 'Target revenue', symbol: { fill: '#FFA838', type: 'minus' } },
                ]}
            />
        </VictoryChart>
    );
}
