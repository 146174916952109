import { useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { SearchIcon, XIcon, PlusSmIcon } from '@heroicons/react/outline';

import client from '../client';
import AutoComplete from './AutoComplete';
import ConfirmationModal from './ConfirmationModal';
import { ClientAka, ClientMatch } from '../types';

export default function MatchCustomerNames() {
    const { mutate } = useSWRConfig();
    const { data: akas = [] } = useSWR(`/v1/clientakas?is_matched=false`);
    const { data: customers = [] } = useSWR(`/v1/clients/`);
    const [searchShownFor, setSearchShownFor] = useState(-1);
    const [selectedCustomer] = useState(null);
    const [matchToAdd, setMatchToAdd] = useState<ClientMatch | null>(null);
    const [customerToAdd, setCustomerToAdd] = useState<ClientAka | null>(null);

    const addMatchToCustomer = async (match: ClientMatch) => {
        await client.patch(`/v1/clientakas/${match.akaId}/`, {
            name: match.name,
            client: match.client,
            account: match.account,
        });
        mutate(`/v1/clientakas?is_matched=false`);
    };

    const addNewCustomer = async (aka: ClientAka) => {
        const res = await client.post(`/v1/clients/`, {
            name: aka.name,
            account: aka.account,
        });
        const clientData = res.data;
        await addMatchToCustomer({
            name: aka.name,
            akaId: aka.id,
            account: aka.account,
            client: clientData.id,
        });
    };

    return (
        <div className="flex flex-col">
            <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <div className="bg-gray-50 sticky top-[6.8rem] z-10 flex border-b border-gray-300 -mt-1">
                            <div className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 w-1/2">
                                Name
                            </div>
                            <div className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/2">
                                Suggested Customers
                            </div>
                        </div>
                        <table className="min-w-full">
                            <colgroup>
                                <col className="sm:w-1/2" />
                                <col className="sm:w-1/2" />
                            </colgroup>
                            <tbody className="bg-white">
                                {akas.map((aka: ClientAka, i: number) => (
                                    <tr key={aka.id} className={(i % 2 === 0 ? undefined : 'bg-gray-50') + ' group'}>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-600 align-top">
                                            <div className="flex flex-row self-start items-center px-2 cursor-default">
                                                <div>{aka.name}</div>
                                                <button
                                                    title="Add customer"
                                                    className="hidden group-hover:inline-flex items-center justify-center rounded-md border border-transparent h-5 w-5 ml-2 
                                                            text-xl font-medium text-white shadow-sm bg-ru-green focus:outline-none"
                                                    onClick={() => {
                                                        setCustomerToAdd(aka);
                                                    }}
                                                >
                                                    <PlusSmIcon className="text-white" />
                                                </button>
                                            </div>
                                        </td>
                                        <td className="text-sm font-medium text-gray-600 py-4 px-3 w-full">
                                            <div className="flex flex-row">
                                                <div className="flex flex-col ">
                                                    {aka?.suggestions.map((customerSuggestion: any, idx: number) => {
                                                        return (
                                                            <div
                                                                key={idx}
                                                                className="flex flex-row self-start items-center pb-1 px-2 hover:bg-gray-100 rounded-md border border-transparent cursor-default"
                                                            >
                                                                <div>{customerSuggestion.matched_to}</div>
                                                                <button
                                                                    title="Add as match"
                                                                    className="hidden group-hover:inline-flex items-center justify-center rounded-md border border-transparent h-5 w-5 ml-2 
                                                            text-xl font-medium text-white shadow-sm bg-ru-blue focus:outline-none"
                                                                    onClick={() =>
                                                                        setMatchToAdd({
                                                                            name: aka.name,
                                                                            akaId: aka.id,
                                                                            account: aka.account,
                                                                            client: customerSuggestion.client_id,
                                                                            customerName: customerSuggestion.matched_to,
                                                                        })
                                                                    }
                                                                >
                                                                    <PlusSmIcon className="text-white" />
                                                                </button>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className="ml-auto">
                                                    {searchShownFor === aka.id ? (
                                                        <div className="flex items-center">
                                                            <AutoComplete
                                                                list={customers}
                                                                current={[selectedCustomer]}
                                                                itemLabelAccessor={(item: any) => item?.name}
                                                                onChange={(customer: any) => {
                                                                    if (customer) {
                                                                        setMatchToAdd({
                                                                            name: aka.name,
                                                                            akaId: aka.id,
                                                                            account: aka.account,
                                                                            client: customer.id,
                                                                            customerName: customer.name,
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                            <XIcon
                                                                className="h-4 w-4 cursor-pointer ml-2"
                                                                onClick={() => setSearchShownFor(-1)}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <SearchIcon
                                                            className="h-6 w-6 cursor-pointer"
                                                            onClick={() => setSearchShownFor(aka.id)}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ConfirmationModal
                title={`Are you sure ${matchToAdd?.name} is "also known as" ${matchToAdd?.customerName}?`}
                isOpen={!!matchToAdd}
                closeModal={() => setMatchToAdd(null)}
                onConfirmation={() => {
                    if (matchToAdd) addMatchToCustomer(matchToAdd);
                    setMatchToAdd(null);
                }}
            />
            <ConfirmationModal
                title={`Are you sure you want to add ${customerToAdd?.name} to your primary customer list?`}
                isOpen={!!customerToAdd}
                closeModal={() => setCustomerToAdd(null)}
                onConfirmation={() => {
                    if (customerToAdd) addNewCustomer(customerToAdd);
                    setCustomerToAdd(null);
                }}
            />
        </div>
    );
}
