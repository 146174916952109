import create from 'zustand';
import { persist } from 'zustand/middleware';
import jwtDecode, { JwtPayload } from 'jwt-decode';

type State = {
    token: string;
    refreshToken: string;
    setToken: (token: string, refreshToken?: string) => void;
    logout: () => void;
    accounts: {
        [id: string]: string;
    };
    selectedAccount: string;
    setSelectedAccount: (accountId: string) => void;
};

type JwtPayloadWithAccounts = JwtPayload & {
    accounts: {
        [id: string]: string;
    };
};

const useAuthStore = create<State>(
    persist(
        (set, get) => ({
            token: '',
            refreshToken: '',
            accounts: {},
            selectedAccount: '',
            setToken: (token: string, refreshToken?: string) => {
                if (refreshToken) {
                    set({ refreshToken });
                }
                const decoded = jwtDecode<JwtPayloadWithAccounts>(token);
                set({ token, accounts: decoded.accounts });
                if (!get().selectedAccount) {
                    set({ selectedAccount: Object.keys(decoded.accounts)[0] || '' });
                }
            },
            setSelectedAccount: (accountId) => {
                set({ selectedAccount: accountId });
            },
            logout: () => set({ token: '', refreshToken: '' }),
        }),
        {
            name: 'auth-storage',
        },
    ),
);

export default useAuthStore;
