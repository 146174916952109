import { useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { PlusSmIcon } from '@heroicons/react/outline';

import client from '../client';
import AutoComplete from './AutoComplete';
import ConfirmationModal from './ConfirmationModal';
import { ProductAka, ProductMatch } from '../types';

export default function MatchProductNames() {
    const { mutate } = useSWRConfig();
    const { data: akas = [] } = useSWR(`/v1/productakas?is_matched=false`);
    const { data: products = [] } = useSWR(`/v1/products/`);
    const [selectedProduct] = useState(null);
    const [matchToAdd, setMatchToAdd] = useState<ProductMatch | null>(null);
    const [productToAdd, setProductToAdd] = useState<ProductAka | null>(null);

    const addMatchToProduct = async (match: ProductMatch) => {
        await client.patch(`/v1/productakas/${match.akaId}/`, {
            name: match.name,
            product: match.product,
            account: match.account,
        });
        mutate(`/v1/productakas?is_matched=false`);
    };

    const addNewProduct = async (aka: ProductAka) => {
        const res = await client.post(`/v1/products/`, {
            name: aka.name,
            account: aka.account,
            code: 'a',
        });
        const productData = res.data;
        await addMatchToProduct({
            name: aka.name,
            akaId: aka.id,
            account: aka.account,
            product: productData.id,
        });
    };

    return (
        <div className="flex flex-col">
            <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <div className="bg-gray-50 sticky top-[6.8rem] z-10 flex border-b border-gray-300 -mt-1">
                            <div className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 w-1/2">
                                Name
                            </div>
                            <div className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/2"></div>
                        </div>
                        <table className="min-w-full">
                            <colgroup>
                                <col className="sm:w-1/2" />
                                <col className="sm:w-1/2" />
                            </colgroup>
                            <tbody className="bg-white">
                                {akas.map((aka: ProductAka, i: number) => (
                                    <tr key={aka.id} className={(i % 2 === 0 ? undefined : 'bg-gray-50') + ' group'}>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-600 align-top">
                                            <div className="flex flex-row self-start items-center px-2 cursor-default">
                                                <div>{aka.name}</div>
                                                {/*
                                                <button
                                                    title="Add product"
                                                    className="hidden group-hover:inline-flex items-center justify-center rounded-md border border-transparent h-5 w-5 ml-2 
                                                            text-xl font-medium text-white shadow-sm bg-ru-green focus:outline-none"
                                                    onClick={() => {
                                                        setProductToAdd(aka);
                                                    }}
                                                >
                                                    <PlusSmIcon className="text-white" />
                                                </button>
                                                */}
                                            </div>
                                        </td>
                                        <td className="text-sm font-medium text-gray-600 py-4 px-3 w-full">
                                            <div className="flex flex-row w-full">
                                                <div className="w-full">
                                                    <div className="flex items-center">
                                                        <AutoComplete
                                                            list={products}
                                                            className="w-full -mt-1"
                                                            placeholder="Type to find a product to match to"
                                                            current={[selectedProduct]}
                                                            itemLabelAccessor={(item: any) => item?.name}
                                                            onChange={(product: any) => {
                                                                if (product) {
                                                                    setMatchToAdd({
                                                                        name: aka.name,
                                                                        akaId: aka.id,
                                                                        account: aka.account,
                                                                        product: product.id,
                                                                        productName: product.name,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ConfirmationModal
                title={`Are you sure ${matchToAdd?.name} is "also known as" ${matchToAdd?.productName}?`}
                isOpen={!!matchToAdd}
                closeModal={() => setMatchToAdd(null)}
                onConfirmation={() => {
                    if (matchToAdd) addMatchToProduct(matchToAdd);
                    setMatchToAdd(null);
                }}
            />
            <ConfirmationModal
                title={`Are you sure you want to add ${productToAdd?.name} to your primary product list?`}
                isOpen={!!productToAdd}
                closeModal={() => setProductToAdd(null)}
                onConfirmation={() => {
                    if (productToAdd) addNewProduct(productToAdd);
                    setProductToAdd(null);
                }}
            />
        </div>
    );
}
