import useSWR from 'swr';
import { Forecast } from '../types';

export default function useForecasts() {
    const { data = [], error, isLoading, mutate } = useSWR<Forecast[]>(`/v1/forecast/`);

    return {
        forecasts: data,
        isLoading,
        isError: error,
        mutate,
    };
}
