import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { MinusSmIcon } from '@heroicons/react/outline';

import client from '../client';
import { ProductAka } from '../types';
import ConfirmationModal from './ConfirmationModal';
import useLayoutStore from '../stores/layout';

export default function ProductList() {
    const setCustomBreadcrumbs = useLayoutStore((state) => state.setBreadcrumbOverride);
    const { data: products = [] } = useSWR(`/v1/products/`);
    const [akaToRemove, setAkaToRemove] = useState<ProductAka | null>(null);

    useEffect(() => {
        setCustomBreadcrumbs([
            { label: 'Products', to: 'products' },
            { label: 'Primary List ', to: '' },
        ]);
    }, [setCustomBreadcrumbs]);

    const removeAkaFromProduct = async (aka: ProductAka) => {
        await client.patch(`/v1/productakas/${aka.id}/`, {
            name: aka.name,
            product: null,
            account: aka.account,
        });
        mutate(`/v1/products/`);
    };

    return (
        <div className="flex flex-col">
            <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <div className="bg-gray-50 sticky top-[6.8rem] z-10 flex border-b border-gray-300 -mt-1">
                            <div className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 w-1/12">
                                Code
                            </div>
                            <div className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 w-3/12">
                                Product Name
                            </div>
                            <div className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 w-2/12">
                                Short Name
                            </div>
                            <div className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 w-2/12">
                                Category
                            </div>
                            <div className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-4/12">
                                Also Known As
                            </div>
                        </div>
                        <table className="min-w-full">
                            <colgroup>
                                <col className="sm:w-1/12" />
                                <col className="sm:w-3/12" />
                                <col className="sm:w-2/12" />
                                <col className="sm:w-2/12" />
                                <col className="sm:w-4/12" />
                            </colgroup>
                            <tbody className="bg-white">
                                {products.map((product: any, i: number) => (
                                    <tr key={product.id} className={`group ${i % 2 === 0 ? '' : 'bg-gray-50'}`}>
                                        <td className="px-3 py-6 text-sm font-medium text-gray-900">
                                            <div className="flex flex-row self-start items-center">
                                                <div className="relative">
                                                    <div className="pl-2">{product.code}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-3 py-6 text-sm font-medium text-gray-900">
                                            <div className="flex flex-row self-start items-center">
                                                <div className="pl-2">{product.name}</div>
                                            </div>
                                        </td>
                                        <td className="px-3 py-6 text-sm text-gray-900">
                                            <div className="flex flex-row self-start items-center">
                                                <div className="relative">
                                                    <div className="pl-2">{product.short_name}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-3 py-6 text-sm text-gray-900">
                                            <div className="flex flex-row self-start items-center">
                                                <div className="relative">
                                                    <div className="pl-2">{product.category_1}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-3 py-6 text-sm text-gray-500">
                                            <div className="flex flex-row">
                                                <div className="flex flex-col ">
                                                    {product.akas
                                                        .filter((aka: any) => aka.name !== product.name)
                                                        .map((aka: any, idx: number, akas: any[]) => {
                                                            return (
                                                                <div
                                                                    key={idx}
                                                                    className="flex flex-row self-start items-center px-2 hover:bg-gray-100 rounded-md border border-transparent cursor-default"
                                                                >
                                                                    <div key={aka.id}>{aka.name}</div>
                                                                    <button
                                                                        title="Unmatch"
                                                                        className="hidden group-hover:inline-flex items-center justify-center rounded-md border border-transparent h-5 w-5 ml-2 
                                                            text-xl font-medium text-white shadow-sm bg-ru-teal focus:outline-none"
                                                                        onClick={() => {
                                                                            setAkaToRemove({
                                                                                ...aka,
                                                                                product: product.id,
                                                                                account: product.account,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <MinusSmIcon className="text-white" />
                                                                    </button>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ConfirmationModal
                title={`Are you sure you want to unmatch ${akaToRemove?.name} from ${
                    products.find((c: any) => c.id === akaToRemove?.product)?.name
                }?`}
                isOpen={!!akaToRemove}
                closeModal={() => setAkaToRemove(null)}
                onConfirmation={() => {
                    if (akaToRemove) removeAkaFromProduct(akaToRemove);
                    setAkaToRemove(null);
                }}
            />
        </div>
    );
}
