import { ReplyIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import client from '../client';
import { classNames, formatDate } from '../utils';
import FormattedInput from './FormattedInput';
import Loading from './Loading';

interface ForecastEditorInputsProps {
    selectedProduct: number | null;
    mutateChart: () => void;
    isCategory: boolean;
}

export default function ForecastEditorInputs({
    selectedProduct,
    mutateChart,
    isCategory = false,
}: ForecastEditorInputsProps) {
    const [strategy, setStrategy] = useState<'strategy_1' | 'strategy_2'>('strategy_1');
    const [totals, setTotals] = useState<[number, number]>([0, 0]);
    const [customPercentage, setCustomPercentage] = useState<number>(0);
    const [productRevenueByIntervals, setProductRevenueByIntervals] = useState<number[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const urlSubstringByForecastType = isCategory ? 'product-categories' : 'products';
    const { data: forecastProduct, mutate: mutateForecastProduct } = useSWR(
        selectedProduct ? `/v1/forecast/${urlSubstringByForecastType}/${selectedProduct}/` : null,
    );
    const { data: forecastProductRevenue, mutate: mutateForecastProductRevenue } = useSWR(
        selectedProduct ? `/v1/forecast/${urlSubstringByForecastType}/${selectedProduct}/revenue/` : null,
    );

    useEffect(() => {
        if (forecastProduct) {
            setTotals([forecastProduct?.total_revenue_start_amount, forecastProduct?.total_revenue_end_amount]);
            setStrategy(forecastProduct.strategy);
        }
    }, [forecastProduct]);

    useEffect(() => {
        if (forecastProductRevenue) {
            setProductRevenueByIntervals(forecastProductRevenue.map((fpr: any) => fpr.target_amount || 0));
        }
    }, [forecastProductRevenue]);

    useEffect(() => {
        if (totals[0] < 0) {
            setTotals((state) => [0, state[1]]);
        } else if (totals[1] < 0) {
            setTotals((state) => [state[0], 0]);
        }
        if (totals[0] && totals[1]) {
            setCustomPercentage(Math.round(((totals[1] - totals[0]) / totals[0]) * 100));
        }
    }, [totals]);

    useEffect(() => {
        setCustomPercentage(0);
    }, [selectedProduct]);

    const handleSave = async () => {
        setIsSaving(true);
        if (strategy === 'strategy_1') {
            const { data: productResponse } = await client.patch(
                `/v1/forecast/${urlSubstringByForecastType}/${selectedProduct}/`,
                {
                    ...forecastProduct,
                    total_revenue_start_amount: (+totals[0] || 0).toFixed(2),
                    total_revenue_end_amount: (+totals[1] || 0).toFixed(2),
                    strategy,
                },
            );
            mutateForecastProduct(productResponse);
            mutateForecastProductRevenue();
        } else if (strategy === 'strategy_2') {
            // we call this endpoint BEFORE updating revenue in order to save strategy change (Strategy 1 to Strategy 2)
            // needed for Strategy 2 condition in pre_save_revenue in signals.py
            await client.patch(`/v1/forecast/${urlSubstringByForecastType}/${selectedProduct}/`, {
                strategy,
            });
            const { data: productRevenueResponse } = await client.patch(`/v1/forecast/${urlSubstringByForecastType}/${selectedProduct}/revenue/`, {
                [isCategory ? 'product_categories' : 'products']: forecastProductRevenue.map((fpr: any, i: number) => {
                    return { ...fpr, target_amount: productRevenueByIntervals[i].toString() };
                }),
            });
            // TODO: refactor this hack - we call this endpoint a 2nd time to update parent Product/Category.TotalRevenueEndAmount
            // needed for Strategy 2 condition in pre_save_product_and_category in signals.py
            await client.patch(`/v1/forecast/${urlSubstringByForecastType}/${selectedProduct}/`, {
                strategy,
            });
            mutateForecastProductRevenue(productRevenueResponse.products);
            mutateForecastProduct();
        }
        setIsSaving(false);
        mutateChart();
    };

    return (
        <>
            <nav className="flex" aria-label="Tabs">
                <div
                    className={classNames(
                        strategy === 'strategy_1'
                            ? 'border-ru-blue'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'mr-8 whitespace-nowrap py-1 px-1 border-b-2 font-medium text-sm cursor-pointer',
                    )}
                    onClick={() => setStrategy('strategy_1')}
                >
                    Auto-Fill by Last Month
                </div>
                <div
                    className={classNames(
                        strategy === 'strategy_2'
                            ? 'border-ru-blue'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap py-1 px-1 border-b-2 font-medium text-sm cursor-pointer',
                    )}
                    onClick={() => setStrategy('strategy_2')}
                >
                    Manual by Period
                </div>
                <button
                    onClick={handleSave}
                    type="button"
                    className="ml-auto items-center justify-center rounded-md border border-transparent bg-gray-400 px-4 py-1 mr-3 text-sm font-medium text-white shadow-sm hover:bg-ru-blue sm:w-auto"
                >
                    {isSaving ? <Loading size="small" /> : 'Save'}
                </button>
            </nav>
            <div className="-my-2 -mx-4 p-4 overflow-x-auto">
                <div className="mt-4 p-4 rounded-lg shadow ring-1 ring-black ring-opacity-5 inline-block min-w-full">
                    {strategy === 'strategy_1' && (
                        <div className="flex flex-row justify-between items-end">
                            <div className="">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Start amount
                                </label>
                                <div className="mt-1 flex items-center">
                                    <FormattedInput
                                        type="text"
                                        value={totals[0] || ''}
                                        onChange={({ target }) => {
                                            setTotals((state) => [+target.value, state[1]]);
                                        }}
                                        name="startAmount"
                                        className="w-32 shadow-sm focus:ring-ru-blue focus:border-ru-blue block sm:text-sm border-gray-300 rounded-md"
                                    />
                                    <div className="h-4 w-4">
                                        {forecastProduct?.total_revenue_start_amount &&
                                            forecastProduct?.total_revenue_start_amount !== totals[0] && (
                                                <ReplyIcon
                                                    onClick={() =>
                                                        setTotals((state) => [
                                                            forecastProduct?.total_revenue_start_amount,
                                                            state[1],
                                                        ])
                                                    }
                                                    className="h-4 w-4 ml-2 text-gray-400 cursor-pointer"
                                                    aria-hidden="true"
                                                />
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flew-row space-x-2 px-3">
                                {[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 150, 200, 300, 500].map((percentage) => (
                                    <button
                                        key={percentage}
                                        onClick={() => {
                                            setTotals((state) => [
                                                state[0],
                                                state[0] ? (state[0] * (100 + percentage)) / 100 : state[1],
                                            ]);
                                        }}
                                        type="button"
                                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-400 p-2 text-xs font-normal text-white shadow-sm hover:bg-ru-blue sm:w-auto"
                                    >
                                        {percentage}%
                                    </button>
                                ))}
                                <div className="flex flew-row items-center">
                                    <input
                                        type="number"
                                        value={customPercentage !== 0 ? customPercentage : ''}
                                        onChange={({ target }) => {
                                            setTotals((state) => [
                                                state[0],
                                                state[0] ? (state[0] * (100 + +target.value)) / 100 : state[1],
                                            ]);
                                            setCustomPercentage(+target.value);
                                        }}
                                        className="shadow-sm focus:ring-ru-blue focus:border-ru-blue block w-16 px-1 sm:text-sm border-gray-300 rounded-md text-right"
                                    />
                                    <div className="ml-1">%</div>
                                </div>
                            </div>
                            <div className="text-right">
                                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                    Target amount
                                </label>
                                <div className="mt-1">
                                    <FormattedInput
                                        type="text"
                                        value={totals[1] || ''}
                                        onChange={({ target }) => {
                                            setTotals((state) => [state[0], +target.value]);
                                        }}
                                        name="endAmount"
                                        className="w-32 shadow-sm focus:ring-ru-blue focus:border-ru-blue block sm:text-sm border-gray-300 rounded-md text-right"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {strategy === 'strategy_2' && (
                        <div className="flex flex-row justify-between space-x-2 items-end text-right">
                            {forecastProductRevenue?.map((fpr: any, idx: number) => {
                                return (
                                    <div key={idx}>
                                        <label className="block text-sm font-medium text-gray-700 pr-3">
                                            {formatDate(new Date(fpr.interval_date), 'y-m')}
                                        </label>
                                        <div className="mt-1 w-32">
                                            <FormattedInput
                                                type="text"
                                                value={productRevenueByIntervals[idx]}
                                                onChange={({ target }) => {
                                                    setProductRevenueByIntervals((state) => {
                                                        return state.map((d, i) => {
                                                            if (i === idx) return +target.value;
                                                            return d;
                                                        });
                                                    });
                                                }}
                                                name="endAmount"
                                                className="w-32 shadow-sm focus:ring-ru-blue focus:border-ru-blue block sm:text-sm border-gray-300 rounded-md text-right"
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
            <div className="flex flex-row mt-2"></div>
        </>
    );
}
