import axios from 'axios';
import useAuthStore from './stores/auth';

var axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
});

axiosInstance.interceptors.request.use((config) => {
    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${useAuthStore.getState().token}`,
        ...(useAuthStore.getState().selectedAccount
            ? { 'selected-account': useAuthStore.getState().selectedAccount }
            : {}),
    };
    return config;
});

axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        if (err.response) {
            // Access Token was expired
            if (err.response.status === 401) {
                if (err.config.url !== '/token/refresh/') {
                    try {
                        const rs = await axiosInstance.post('/token/refresh/', {
                            refresh: useAuthStore.getState().refreshToken,
                        });
                        const { access, refresh } = rs.data;
                        useAuthStore.setState({ token: access });
                        if (refresh) {
                            useAuthStore.setState({ refreshToken: refresh });
                        }
                        return axiosInstance({
                            ...err.config,
                            headers: {
                                ...err.config.headers,
                                Authorization: `Bearer ${access}`,
                            },
                        });
                    } catch (_error: any) {
                        if (_error.response && _error.response.data) {
                            return Promise.reject(_error.response.data);
                        }
                        return Promise.reject(_error);
                    }
                } else {
                    useAuthStore.getState().logout();
                }
            }
            if (err.response.status === 403 && err.response.data) {
                return Promise.reject(err.response.data);
            }
        }
        return Promise.reject(err);
    },
);

export default axiosInstance;
