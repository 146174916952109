import * as React from 'react';
import { getTrackBackground, Range } from 'react-range';

interface RangeInputProps {
    options: any;
    values: any;
    onChange: any;
    widthRatio: number;
    startOffset: number;
}

const RangeInput: React.FC<RangeInputProps> = ({ options, values, onChange, widthRatio, startOffset }) => {
    const MIN = options[0].value;
    const MAX = options[options.length - 1].value;

    if (MIN === MAX) return null;
    if (values.some((v: any) => v > MAX || v < MIN)) {
        return null;
    }

    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
            }}
            className="pt-4 basis-full relative"
        >
            <Range
                draggableTrack
                values={values}
                step={1}
                min={MIN}
                max={MAX}
                rtl={false}
                onChange={onChange}
                renderTrack={({ props, children }) => (
                    <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                            ...props.style,
                            height: '36px',
                            display: 'flex',
                            position: 'relative',
                            left: `${startOffset * 100}%`,
                            width: `${widthRatio * 100}%`,
                        }}
                    >
                        <div
                            ref={props.ref}
                            style={{
                                height: '5px',
                                width: '100%',
                                borderRadius: '4px',
                                background: getTrackBackground({
                                    values,
                                    colors: ['#ccc', '#1a4a43', '#ccc'],
                                    min: MIN,
                                    max: MAX,
                                }),
                                alignSelf: 'center',
                            }}
                        >
                            {children}
                        </div>
                    </div>
                )}
                renderThumb={({ index, props, isDragged }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: '26px',
                            width: '26px',
                            borderRadius: '50%',
                            backgroundColor: '#FFF',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0px 2px 6px #AAA',
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: '-32px',
                                color: '#7a7a7a',
                                fontWeight: 500,
                                fontSize: '14px',
                                fontFamily: 'Montserrat,sans-serif',
                                padding: '4px',
                                width: '150px',
                                textAlign: 'center',
                            }}
                        >
                            {options.find((o: any) => o.value === values[index])?.label}
                        </div>
                    </div>
                )}
            />
        </div>
    );
};

export default RangeInput;
