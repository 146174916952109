/* This example requires Tailwind CSS v2.0+ */
import { Switch } from '@headlessui/react';
import { classNames } from '../utils';

export default function Toggle({ value = false, onChange = () => {}, disabled = false }) {
    return (
        <Switch
            checked={value}
            disabled={disabled}
            onChange={onChange}
            className={classNames(
                value ? 'bg-ru-teal' : 'bg-gray-200',
                disabled ? 'cursor-not-allowed' : '',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-transparent',
            )}
        >
            <span className="sr-only">Use setting</span>
            <span
                aria-hidden="true"
                className={classNames(
                    value ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                )}
            />
        </Switch>
    );
}
