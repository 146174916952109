import { useContext, useMemo, useState } from 'react';
import { createBarData } from '../screens/ForecastEdit';
import { isSameMonth } from 'date-fns';
import { GapAnalysisChartDataContext } from '../screens/Analysis';
import { isDateInForecast, dateHasInvoiceData } from '../utils';

interface ForecastPeriodBarProps {
    forecastA: any;
    forecastB: any;
    alignment: 'top' | 'bottom';
    actualsShowable: boolean;
}

export default function ForecastPeriodBar({ forecastA, forecastB, alignment = 'top', actualsShowable }: ForecastPeriodBarProps) {
    const [hoveredDate, setHoveredDate] = useState('');

    const { useActualsData, allMonths } = useContext(GapAnalysisChartDataContext);
    const showActuals = useMemo(() => {
        return actualsShowable && useActualsData;
    }, [useActualsData, actualsShowable]);

    return (
        <div className="flex basis-full items-end">
            {allMonths.map((d: any, i: number) => {
                const isInForecast = isDateInForecast(forecastA, d);
                const isInOtherForecast = isDateInForecast(forecastB, d);
                const isPreviousInForecast = isDateInForecast(forecastA, allMonths[i - 1]);
                const isNextInForecast = isInForecast ? isDateInForecast(forecastA, allMonths[i + 1]) : null;

                return (
                    <div
                        key={i}
                        onMouseEnter={() => {
                            setHoveredDate(d.label);
                        }}
                        onMouseLeave={() => {
                            setHoveredDate('');
                        }}
                        className="flex-1 relative border-gray-500 border-2 border-r-0 last:border-r-2"
                    >
                        {((isInForecast && (!isPreviousInForecast || !isNextInForecast)) ||
                            hoveredDate === d.label) && (
                            <div
                                className={`absolute ${
                                    alignment === 'top' ? 'bottom-5' : 'top-5'
                                } text-xs left-1/2 -translate-x-1/2 whitespace-nowrap`}
                            >
                                {d.label}
                            </div>
                        )}
                        <div
                            className={`flex h-4 ${getPeriodColor(isInForecast, isInOtherForecast, !!showActuals)}`}
                        ></div>
                    </div>
                );
            })}
        </div>
    );
}
const getPeriodColor = (IsInForecast: boolean, isInOtherForecast: boolean, actualsShowable: boolean) => {
    if (IsInForecast) {
        if (isInOtherForecast) return 'bg-ru-teal';
        else return 'bg-ru-green';
    } else {
        if (actualsShowable) return 'bg-ru-blue';
        else return '';
    }
};
