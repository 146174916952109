import create from 'zustand';
import { persist } from 'zustand/middleware';

type State = {
    forecastsToPoll: number[];
    addForecastToPolling: (id: number) => void;
    clearForecastFromPolling: (id: number) => void;
};

const useForecastStore = create<State>(
    persist(
        (set, get) => ({
            forecastsToPoll: [],
            addForecastToPolling: (id: number) => {
                set((state) => ({
                    ...state,
                    forecastsToPoll: [...state.forecastsToPoll, id],
                }));
            },
            clearForecastFromPolling: (id: number) => {
                set((state) => ({
                    ...state,
                    forecastsToPoll: state.forecastsToPoll.filter((f) => f !== id),
                }));
            },
        }),
        {
            name: 'forecast-storage',
        },
    ),
);

export default useForecastStore;
