import { classNames, formatDate, formatToEuros } from '../utils';
import Loading from './Loading';

interface ForecastProductTableProps {
    chartData: any; //TODO
    amountsList: any;
    startDate: string;
    invoiceDataEndDate: string;
}

export default function ForecastProductTable({
    chartData,
    amountsList,
    startDate = '',
    invoiceDataEndDate,
}: ForecastProductTableProps) {
    return (
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                    Revenue Type
                                </th>
                                {chartData?.bar_data.map((d: any, i: number) => (
                                    <th
                                        key={i}
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-normal text-gray-900"
                                    >
                                        {formatDate(new Date(d.interval_date), 'm-y')}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {chartData ? (
                                amountsList.map((amounts: any, idx: number) => (
                                    <tr className={classNames(idx === 4 ? 'divide-y-2 divide-gray-400' : '')} key={idx}>
                                        <td
                                            className={classNames(
                                                'whitespace-nowrap w-12 py-2 pl-4 pr-3 text-sm text-right text-gray-900 sm:pl-6',
                                                idx === 4 ? 'border-t-2 border-gray-400 border-solid' : '',
                                            )}
                                        >
                                            {amounts?.[0]?.label?.split(' ')[0]}
                                        </td>
                                        {amounts.map((d: any, i: number) => (
                                            <td
                                                key={i}
                                                className={classNames(
                                                    'whitespace-nowrap w-10 px-2 py-2 text-[13px] text-right text-gray-900 tabular-nums',
                                                    idx === 4 ? 'font-medium' : 'font-light',
                                                )}
                                            >
                                                {(d.date < new Date(startDate) && idx !== 0) ||
                                                (idx === 0 && d.date > new Date(invoiceDataEndDate)) // do not show always 0 values
                                                    ? null
                                                    : formatToEuros(d.amount, {
                                                          maximumFractionDigits: 0,
                                                          style: 'decimal',
                                                      })}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td>
                                        <div className="flex flex-row items-center justify-center my-3">
                                            <Loading size="large" />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
